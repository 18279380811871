<!-- Invoice Modal -->
<div
  class="modal fade"
  id="errorModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <!-- Header -->
      <div
        class="modal-top-cover text-center bg-primary"
        style="
          background-image: url(assets/svg/components/error-abstract-shapes.svg);
        "
      >
        <figure class="position-absolute right-0 bottom-0 left-0">
          <svg
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
          >
            <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
          </svg>
        </figure>

        <div class="modal-close">
          <button
            type="button"
            class="btn btn-icon btn-sm btn-ghost-light"
            data-dismiss="modal"
            aria-label="Close"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 18 18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="currentColor"
                d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"
              />
            </svg>
          </button>
        </div>
      </div>
      <!-- End Header -->

      <div class="modal-top-cover-avatar">
        <img
          class="avatar avatar-lg avatar-circle avatar-border-lg avatar-centered shadow-soft"
          src="assets/svg/components/error.svg"
          alt="Logo"
        />
      </div>

      <!-- Body -->
      <div class="modal-body pt-3 pb-sm-5 px-sm-5">
        <div class="text-center mb-5">
          <h3 class="mb-1">{{ s.getLocale("error/error") }}</h3>
          <span class="d-block">{{ s.getLocale("error/message") }}</span>
        </div>

        <div class="row mb-6">
          {{ s.getLocale("error/reason/file") }}
        </div>

        <hr class="my-5" />

        <p class="modal-footer-text">
          {{ s.getLocale("error/contact") }}<br />
          <a href="mailto:andre.wisen@gmail.com">andre.wisen@gmail.com</a>
        </p>
      </div>
      <!-- End Body -->
    </div>
  </div>
</div>
<!-- End Invoice Modal -->
