const getFlowData = (logs) => getData(logs, "flow");
const getPressureData = (logs) => getData(logs, "pressure");
const getVolumeData = (logs) => getData(logs, "volume");

const getData = (logs, dataType) => {
  const data = [];
  logs.forEach((operatingLog) => {
    const x = operatingLog.logTime.unixTimestamp;
    const y = operatingLog[dataType].value;
    data.push({ x, y });
  });
  return data;
};

export { getFlowData, getPressureData, getVolumeData };
