<!-- ========== HEADER ========== -->
<header
  id="header"
  class="header header-bg-transparent header-abs-top py-3 bg-white"
>
  <div class="header-section">
    <div id="logoAndNav" class="container">
      <!-- Nav -->
      <nav class="navbar">
        <div class="ml-auto">
          <a
            class="btn btn-sm btn-outline-primary transition-3d-hover"
            href="/settings"
          >
            {{ s.getLocale("settings") }}
            <i class="fas fa-chevron-right ml-1"></i>
          </a>
        </div>
      </nav>
      <!-- End Nav -->
    </div>
  </div>
</header>
<!-- ========== END HEADER ========== -->
