import * as moment from 'moment';
import { ChartOptions } from 'chart.js';
/**
 * Chart.js Options
 * https://www.chartjs.org/docs/latest/general/options.html
 */

/**** EXAMPLE ****
 * options: {
 *    scales: {
 *      yAxes: [{
 *        ticks: {
 *          beginAtZero: true
 *        }
 *      }]
 *    }
 * }
 **** EXAMPLE ****/

// const chartOptions: ChartOptions = {
//   scales: {
//     xAxes: [
//       {
//         ticks: {
//           callback: function (label, index, labels) {
//             return moment(label).format('HH:mm:ss');
//           },
//         },
//         type: 'linear',
//         position: 'bottom',
//         scaleLabel: {
//           display: true,
//           labelString: 'Time',
//         },
//       },
//     ],
//     yAxes: [
//       {
//         id: 'y1',
//         position: 'left',
//         scaleLabel: {
//           display: true,
//           labelString: 'Flow & Pressure',
//         },
//       },
//       {
//         id: 'y2',
//         position: 'right',
//         scaleLabel: {
//           display: true,
//           labelString: 'Volume',
//         },
//       },
//     ],
//   },
// };

const covertTime = (label: number): string => moment(label).format('HH:mm');

const chartOptions: ChartOptions = {
  /**
   * TODO:
   * >2 should work fine for printing
   */
  devicePixelRatio: 2,
  scales: {
    xAxes: [
      {
        gridLines: {
          zeroLineWidth: 0,
          drawBorder: true,
          drawOnChartArea: true,
        },
        ticks: {
          callback: function (label, index, labels) {
            return covertTime(<number>label);
          },
        },
        type: 'linear',
        position: 'bottom',
      },
    ],
    yAxes: [
      // Pressure & Flow
      {
        id: 'y1',
        position: 'left',
        gridLines: {
          zeroLineWidth: 0,
          drawBorder: false,
          drawOnChartArea: true,
        },
      },
      // Volume
      {
        id: 'y2',
        position: 'right',
        gridLines: {
          zeroLineWidth: 0,
          drawBorder: false,
          display: true,
          // drawOnChartArea: false,
        },
        ticks: {
          display: true,
        },
      },
    ],
  },
};
export { chartOptions, covertTime };
