const updateGraphsSelector = (inputString: string, maxPage: number) => {
  const pages = [];
  const added = {};
  const ranges: string[] = inputString.split(/,|\u3001/);

  for (const range of ranges) {
    /**
     * Check range
     */
    if (range === '') return; // Empty
    const limits = range.split('-');
    if (limits.length > 2) return; // Wrong format
    /**
     * Get min value
     */
    let min = parseIntStrict(limits[0]);
    if ((limits[0].length > 0 && Number.isNaN(min)) || min < 1) return;
    if (limits.length === 1) {
      if (min > maxPage) return;
      if (!added.hasOwnProperty(min)) {
        pages.push(min);
        added[min] = true;
      }
      continue;
    }
    /**
     * Get Max
     */
    let max = parseIntStrict(limits[1]);
    if (Number.isNaN(max) && limits[1].length > 0) return;
    /**
     * Edge Case
     */
    if (Number.isNaN(min)) {
      min = 1;
    }
    if (Number.isNaN(max)) {
      max = maxPage;
    }
    /**
     * Final Check
     */
    if (min > max) return;
    if (max > maxPage) return;
    /**
     * Add
     */
    for (let i = min; i <= max; i++) {
      if (!added.hasOwnProperty(i)) {
        pages.push(i);
        added[i] = true;
      }
    }
  }
  return pages;
};
/**
 * Used in place of Number.parseInt(), to ensure values like '1  2' or '1a2' are
 * not allowed.
 * @param {string} value The value to convert to a number.
 * @return {number} The value converted to a number, or NaN if it cannot be
 *     converted.
 * @private
 */
const parseIntStrict = (value) => {
  if (/^\d+$/.test(value.trim())) {
    return Number(value);
  }
  return NaN;
};

export { updateGraphsSelector };
