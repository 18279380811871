import { Injectable } from '@angular/core';
import { Settings } from 'src/settings/settings';
@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  public settings = new Settings();
  // Input OnChange Event
  public event: Event;
  constructor() {}
}
