<!-- ========== HEADER ========== -->
<app-header></app-header>
<!-- ========== HEADER ========== -->

<!-- ========== GRAPHS ========== -->
<div class="bg-light">
  <div class="container space-top-4 space-bottom-2">
    <div *ngIf="fileLoaded; then viewGraphs; else uploadFile"></div>
    <!--  View Graphs -->
    <ng-template #viewGraphs>
      <!-- <div class="space-1"></div> -->
      <div class="graphs">
        <div class="row">
          <!-- Graphs -->
          <div class="col-8">
            <!-- Title -->
            <div class="row justify-content-between align-items-end">
              <div class="col-6">
                <h2 class="h5 mb-0">Graphs</h2>
              </div>
              <div class="col-6 text-right">
                <a class="js-toggle-state link-underline" href="javascript:;">
                  {{ graphsArray.length }} graphs
                </a>
              </div>
            </div>
            <!-- End Title -->
            <!-- Divider  -->
            <hr class="mt-2 mb-4" />
            <!-- End Divider  -->
            <div class="graph" *ngFor="let graph of graphsArray">
              <div
                id="{{ 'canvas-' + graph.number }}"
                [attr.data-number]="graph.number"
                class="space-1"
              >
                <!-- Card -->
                <div class="card">
                  <div class="card-body">
                    <canvas
                      baseChart
                      #myChart="base-chart"
                      [id]="graph.number"
                      [datasets]="graph.data"
                      [options]="graph.options"
                      chartType="scatter"
                    >
                    </canvas>
                  </div>
                </div>
                <!-- End Card -->
              </div>
            </div>
          </div>
          <!-- End Graphs  -->

          <!-- Sidebar -->
          <div class="col-4">
            <!-- Sticky Block Start Point -->
            <div id="stickyBlockStartPoint"></div>
            <!-- End Sticky Block Start Point -->

            <!-- Sticky Content -->
            <div
              class="js-sticky-block"
              data-hs-sticky-block-options='{
           "parentSelector": "#stickyBlockStartPoint",
           "startPoint": "#stickyBlockStartPoint",
           "endPoint": "#stickyBlockEndPoint",
           "stickyOffsetTop": 50,
           "stickyOffsetBottom": 0
         }'
            >
              <!-- Title -->
              <div class="row justify-content-between align-items-end">
                <div class="col-6">
                  <h2 class="h5 mb-0">Actions</h2>
                </div>
                <div class="col-6 text-right">
                  <a class="js-toggle-state link-underline" href="javascript:;">
                    Foo
                  </a>
                </div>
              </div>
              <!-- End Title -->
              <!-- Divider  -->
              <hr class="mt-2 mb-4" />
              <div class="space-top-1"></div>
              <!-- Divider  -->
              <!-- Basics Accordion -->
              <div id="basicsAccordion">
                <!-- Card -->
                <div class="card shadow-soft overflow-hidden mb-3">
                  <div class="card-header card-collapse" id="basicsHeadingOne">
                    <button
                      type="button"
                      class="btn btn-link btn-block card-btn d-flex justify-content-between p-3"
                      data-toggle="collapse"
                      data-target="#basicsCollapseOne"
                      aria-expanded="true"
                      aria-controls="basicsCollapseOne"
                    >
                      <span>
                        <i class="fas fa-arrows-alt-v mr-1"></i> Navigate
                      </span>

                      <span class="card-btn-toggle">
                        <span class="card-btn-toggle-default">+</span>
                        <span class="card-btn-toggle-active">−</span>
                      </span>
                    </button>
                  </div>
                  <div
                    id="basicsCollapseOne"
                    class="collapse show"
                    aria-labelledby="basicsHeadingOne"
                    data-parent="#basicsAccordion"
                  >
                    <div class="card-body">
                      <!-- Range Slider -->
                      <input
                        type="text"
                        class="js-range-slider"
                        name="my_range"
                        value=""
                      />
                      <!-- End Range Slider -->
                    </div>
                  </div>
                </div>
                <!-- End Card -->

                <!-- Card -->
                <div class="card shadow-soft overflow-hidden mb-3">
                  <div class="card-header card-collapse" id="basicsHeadingTwo">
                    <button
                      type="button"
                      class="btn btn-link btn-block card-btn d-flex justify-content-between collapsed p-3"
                      data-toggle="collapse"
                      data-target="#basicsCollapseTwo"
                      aria-expanded="false"
                      aria-controls="basicsCollapseTwo"
                    >
                      <span>
                        <i class="fas fa-calculator mr-1"></i> Estimate aperture
                      </span>
                      <span class="card-btn-toggle">
                        <span class="card-btn-toggle-default">+</span>
                        <span class="card-btn-toggle-active">−</span>
                      </span>
                    </button>
                  </div>
                  <div
                    id="basicsCollapseTwo"
                    class="collapse"
                    aria-labelledby="basicsHeadingTwo"
                    data-parent="#basicsAccordion"
                  >
                    <div class="card-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life
                      accusamus terry richardson ad squid. 3 wolf moon officia
                      aute, non cupidatat skateboard dolor brunch. Food truck
                      quinoa nesciunt laborum eiusmod.

                      <form>
                        <div class="form-group">
                          <label
                            class="input-label"
                            for="exampleFormControlInput1"
                            >Select hole(s)</label
                          >
                          <input
                            (input)="onChange($event)"
                            type="text"
                            [ngClass]="
                              inputPages === undefined
                                ? undefined
                                : inputPages
                                ? 'is-valid'
                                : 'is-invalid'
                            "
                            class="form-control"
                            placeholder="e.g. 1-5, 8, 11-13"
                          />
                          <div *ngIf="inputPages !== undefined">
                            <div
                              *ngIf="inputPages; then valid; else invalid"
                            ></div>

                            <ng-template #valid>
                              <span class="text-success font-size-1"
                                >Okey, let's go!</span
                              ></ng-template
                            >
                            <ng-template #invalid>
                              <span class="text-danger font-size-1"
                                >Invalid page range, use e.g. 1-5, 8,
                                11-13</span
                              ></ng-template
                            >
                          </div>
                        </div>
                        <!-- Button -->
                        <div class="row align-items-center">
                          <button
                            type="submit"
                            class="btn btn-primary btn-block transition-3d-hover mx-3"
                          >
                            Estimate
                          </button>
                        </div>
                        <!-- End Button -->
                      </form>
                    </div>
                  </div>
                </div>
                <!-- End Card -->

                <!-- Card -->
                <div class="card shadow-soft overflow-hidden mb-3">
                  <div
                    class="card-header card-collapse"
                    id="basicsHeadingThree"
                  >
                    <button
                      type="button"
                      class="btn btn-link btn-block card-btn d-flex justify-content-between collapsed p-3"
                      data-toggle="collapse"
                      data-target="#basicsCollapseThree"
                      aria-expanded="false"
                      aria-controls="basicsCollapseThree"
                    >
                      <span> <i class="fas fa-download mr-1"></i> Export </span>

                      <span class="card-btn-toggle">
                        <span class="card-btn-toggle-default">+</span>
                        <span class="card-btn-toggle-active">−</span>
                      </span>
                    </button>
                  </div>
                  <div
                    id="basicsCollapseThree"
                    class="collapse"
                    aria-labelledby="basicsHeadingThree"
                    data-parent="#basicsAccordion"
                  >
                    <div class="card-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life
                      accusamus terry richardson ad squid. 3 wolf moon officia
                      aute, non cupidatat skateboard dolor brunch. Food truck
                      quinoa nesciunt laborum eiusmod.
                      <div class="row align-items-center mt-2">
                        <button
                          type="submit"
                          class="btn btn-primary btn-block transition-3d-hover mx-3"
                          (click)="exportGraphs()"
                        >
                          Export
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End Card -->
              </div>
              <!-- End Basics Accordion -->
            </div>
            <!-- End Sticky Content -->
          </div>
          <!-- End Sidebar -->
        </div>
      </div>
      <!-- Sticky Block End Point -->
      <div id="stickyBlockEndPoint"></div>
      <!-- End Sticky Block End Point -->
    </ng-template>
    <!-- End View Graphs -->

    <!-- File Upload -->
    <ng-template #uploadFile>
      <div>Uplad file (again)</div>
      <!-- File Attachment Input -->
      <div class="">
        <label class="file-attachment-input bg-light" for="ifcFileInput">
          <span id="customFileExample4 ">
            <div class="h4">
              {{ s.getLocale("hero/click") }}
              <span
                class="text-primary text-highlight-primary font-weight-bold"
                >{{ s.getLocale("hero/here") }}</span
              >
              {{ s.getLocale("hero/select-xml-file") }}
            </div>
          </span>

          <input
            (change)="fileChangeEvent($event)"
            id="ifcFileInput"
            name="file-attachment"
            type="file"
            class="js-file-attach file-attachment-input-label"
          />
        </label>
      </div>
      <!-- End File Attachment Input -->
    </ng-template>
    <!-- End File Upload  -->

    <!-- Back Button -->
    <div class="space-1">
      <button (click)="back()" class="btn btn-outline-primary btn-block">
        <i class="fas fa-chevron-left mr-1"></i> {{ s.getLocale("back") }}
      </button>
    </div>
    <!-- End Back Button -->
  </div>
</div>
<!-- ========== GRAPHS ========== -->

<!-- ========== MODALS ========== -->
<app-error></app-error>
<!-- ========== MODALS ========== -->
