import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { checkFile } from 'src/check-file/check-file';
/**
 * jQuery
 */
declare const $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    private settingsService: SettingsService,
    private router: Router
  ) {}
  public s = this.settingsService.settings;

  ngOnInit(): void {}
  ngAfterView(): void {
    // $(function () {
    //   $('.home-popover').popover({
    //     container: 'body',
    //   });
    // });
  }

  fileChangeEvent(event: Event): void {
    if (checkFile(event) === false) {
      $('#errorModal').modal('toggle');
      return;
    }
    this.settingsService.event = event;
    this.router.navigate(['/graphs']);
  }
}
