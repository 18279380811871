<app-header></app-header>
<!-- ========== SETTINGS ========== -->
<div class="gradient-y-gray">
  <div class="container">
    <div class="space-top-3"></div>
    <!-- <h2>
      {{ s.getLocale("settings") }}
    </h2>
    <p>
      Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repudiandae
      veritatis nulla, quidem quibusdam totam fuga nostrum incidunt excepturi
      tempora non aliquid quia enim ducimus, animi et reprehenderit eaque rerum
      sint!
    </p> -->

    <!-- Hero -->
    <!-- Hero Section -->
    <div class="container space-2">
      <div class="row align-items-lg-center">
        <div class="col-lg-5 mb-7 mb-lg-0">
          <div class="mb-4">
            <h1>{{ s.getLocale("settings") }}</h1>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p>
          </div>
        </div>

        <div class="col-lg-7">
          <img
            class="img-fluid"
            src="/assets/svg/components/person-with-boards.svg"
            alt="Settings "
          />
        </div>
      </div>
    </div>
    <!-- End Hero Section -->
    <!-- End Hero -->

    <!-- Settings -->
    <div id="settings ">
      <!-- General Settings -->
      <div id="generalSettings">
        <app-settings-general></app-settings-general>
      </div>
      <!-- End General Settings -->
      <div class="space-1"></div>
      <!-- Graph Settings -->
      <div id="graphSettings">
        <app-settings-graph></app-settings-graph>
      </div>
      <!-- Graph Settings -->
    </div>
    <!-- End Settings -->
    <!-- Back Button -->

    <!-- CTA Section -->
    <div class="container space-bottom-2">
      <div
        class="text-center py-6"
        style="
          background: url(../assets/svg/components/abstract-shapes-19.svg)
            center no-repeat;
        "
      >
        <span class="d-block">
          <button (click)="back()" class="btn btn-primary mr-1">
            <i class="fas fa-chevron-left mr-1"></i> {{ s.getLocale("back") }}
          </button>
          <button (click)="reset()" class="btn btn-outline-primary ml-1">
            {{ s.getLocale("settings/reset") }}
            <i class="far fa-trash-alt ml-1"></i>
          </button>
        </span>
      </div>
    </div>
    <!-- End CTA Section -->

    <!-- <div class="space-1">
      <button (click)="back()" class="btn btn-outline-primary btn-block">
        <i class="fas fa-chevron-left mr-1"></i> {{ s.getLocale("back") }}
      </button>
      <button (click)="reset()" class="btn btn-ghost-primary btn-block">
        {{ s.getLocale("settings/reset") }}
        <i class="far fa-trash-alt ml-1"></i>
      </button>
    </div> -->
    <!-- End Back Button -->
    <!-- Image -->
    <!-- <img
      class="img-fluid"
      src="/assets/svg/components/person-with-boards.svg
"
      alt="SVG"
    /> -->
    <!-- End Image -->
  </div>
</div>
<!-- ========== END SETTINGS ========== -->
<app-footer></app-footer>
