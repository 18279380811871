import { LocalStorage } from './storage/storage';
import { Locales } from './locales/locales';
import {
  generalOptions,
  defaultGeneralOptions,
  graphOptions,
  graphOptionsGroup,
  defaultGraphOptions,
  defualtTemplateOptions,
  colorOptions,
  defaultColorOptions,
} from './options/options';

/**
 * > View or modify the list of things that can be set
 */
class Settings {
  /**
   * > Things are already set. Options to change them
   */
  public graphOptionsGroup;
  public generalOptions;
  public graphOptions;
  public colorOptions;
  public options;

  /**
   * Defaults
   */
  public defaultGeneralOptions = defaultGeneralOptions;
  public defaultGraphOptions = defaultGraphOptions;
  public defualtTemplateOptions = defualtTemplateOptions;
  public defaultColorOptions = defaultColorOptions;
  public defualtOptions = [
    ...this.defaultGeneralOptions,
    ...this.defaultGraphOptions,
    ...this.defualtTemplateOptions,
    ...this.defaultColorOptions,
  ];
  /**
   * The localStorage stores all the settings, both in RAM and localStorage
   */
  public localStorage;
  /**
   * Localization for entire app, including settings.
   * The key property is used to select only settings locales
   */
  public settingsKey = 'settings/';
  public locales;
  constructor() {
    const graphOptions = [];
    Object.values(graphOptionsGroup).forEach((group) => {
      Object.values(group.options).forEach((option) =>
        graphOptions.push(option)
      );
    });
    this.graphOptionsGroup = graphOptionsGroup;
    this.generalOptions = generalOptions;
    this.graphOptions = graphOptions;
    this.colorOptions = colorOptions;
    this.options = [...generalOptions, ...graphOptions, ...colorOptions];

    this.updateDefualtOptions();
    this.localStorage = new LocalStorage(this.defualtOptions);
    const language = this.localStorage.get(this.updateKey('language'));
    this.locales = new Locales(language);
  }
  updateDefualtOptions = () => {
    this.defualtOptions.forEach((option) => {
      const key = Object.keys(option)[0];
      delete Object.assign(option, {
        [`${this.settingsKey}${key}`]: option[key],
      })[key];
    });
  };
  /**
   *
   * @param key Option to be changed
   * @param value New value
   */
  setOption = (key: string, value: string): void =>
    this.localStorage.set(this.updateKey(key), value);
  /**
   * Get a specific option
   */
  getOption = (key: string): string =>
    this.localStorage.get(this.updateKey(key));
  /**
   * Syntax sugar for getting the language
   */
  getLanguage = (): string => this.getOption('language');
  /**
   *
   * @param key Option to be transalted
   */
  getSettingsLocale = (key: string): string => {
    const language = this.localStorage.get(this.updateKey('language'));
    return this.locales.get(language, this.updateKey(key));
  };
  getLocale = (key: string): string => {
    const language = this.localStorage.get(this.updateKey('language'));
    return this.locales.get(language, key);
  };
  /**
   * Add the "settings" prefix
   */
  updateKey = (key: string): string => `${this.settingsKey}${key}`;
}

export { Settings };
