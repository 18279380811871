import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PlaygroundComponent } from './dev/playground/playground.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './dev/home/home.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ErrorComponent } from './components/error/error.component';
import { GraphsComponent } from './components/graphs/graphs.component';
import { SettingsGraphComponent } from './components/settings/settings-graph/settings-graph.component';
import { SettingsGeneralComponent } from './components/settings/settings-general/settings-general.component';
/**
 * https://github.com/valor-software/ng2-charts
 */
import { ChartsModule } from 'ng2-charts';
/**
 * https://github.com/KillerCodeMonkey/ngx-quill
 */
import { QuillModule } from 'ngx-quill';
/**
 * https://github.com/afry/quill-mention
 */
import 'quill-mention';
/**
 * https://github.com/scttcper/ngx-color
 */
import { ColorSketchModule } from 'ngx-color/sketch';
import { ColorCompactModule } from 'ngx-color/compact';
import { ColorTwitterModule } from 'ngx-color/twitter';

@NgModule({
  declarations: [
    AppComponent,
    PlaygroundComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    SettingsComponent,
    ErrorComponent,
    GraphsComponent,
    SettingsGraphComponent,
    SettingsGeneralComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ChartsModule,
    QuillModule.forRoot(),
    ColorSketchModule,
    ColorCompactModule,
    ColorTwitterModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
