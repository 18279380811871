import { values as v, Values as V } from './values';
class Locales {
  public language: string;
  public values: V = v;
  constructor(language: string) {
    this.language = language;
  }
  get = (language: string, key: string): string => this.values[language][key];
}

export { Locales };
