interface Options {
  [key: string]: { [key: string]: string };
}
interface Setting {
  id: string;
  locales: string;
  type: string;
  options: Options;
  images?: any;
}
interface ColorSetting {
  id: string;
  locales: string;
  type: string;
}

const types = ['select', 'radio', 'checkbox'];
const language: Setting = {
  id: 'language',
  locales: '',
  type: 'select',
  options: {
    sv: { sv: 'Svenska', en: 'Engelska' },
    en: { sv: 'Swedish', en: 'English' },
  },
};

const units: Setting = {
  id: 'units',
  locales: '',
  type: 'select',
  options: {
    sv: { metric: 'Metersystemet' },
    en: { metric: 'Metric System' },
  },
};

const generalOptions = [language];
const defaultGeneralOptions = [{ language: 'en' }, { units: 'metric' }];

const showX1: Setting = {
  id: 'graph/showFlow',
  locales: '',
  type: 'checkbox',
  options: {
    sv: { yes: 'Ja', no: 'Nej' },
    en: { yes: 'Yes', no: 'No' },
  },
};
const showY1: Setting = {
  id: 'graph/showPressure',
  locales: '',
  type: 'checkbox',
  options: {
    sv: { yes: 'Ja', no: 'Nej' },
    en: { yes: 'Yes', no: 'No' },
  },
};
const showY2: Setting = {
  id: 'graph/showVolume',
  locales: '',
  type: 'checkbox',
  options: {
    sv: { yes: 'Ja', no: 'Nej' },
    en: { yes: 'Yes', no: 'No' },
  },
};

const showChartTitle: Setting = {
  id: 'graph/showChartTitle',
  locales: '',
  type: 'checkbox',
  options: {
    sv: { yes: 'Ja', no: 'Nej' },
    en: { yes: 'Yes', no: 'No' },
  },
};

const showChartLegend: Setting = {
  id: 'graph/showChartLegend',
  locales: '',
  type: 'checkbox',
  options: {
    sv: { yes: 'Ja', no: 'Nej' },
    en: { yes: 'Yes', no: 'No' },
  },
};
const showX1Label: Setting = {
  id: 'graph/showX1Label',
  locales: '',
  type: 'checkbox',
  options: {
    sv: { yes: 'Ja', no: 'Nej' },
    en: { yes: 'Yes', no: 'No' },
  },
};

const showY1Label: Setting = {
  id: 'graph/showY1Label',
  locales: '',
  type: 'checkbox',
  options: {
    sv: { yes: 'Ja', no: 'Nej' },
    en: { yes: 'Yes', no: 'No' },
  },
};
const showY2Label: Setting = {
  id: 'graph/showY2Label',
  locales: '',
  type: 'checkbox',
  options: {
    sv: { yes: 'Ja', no: 'Nej' },
    en: { yes: 'Yes', no: 'No' },
  },
};
const showX1GridLines: Setting = {
  id: 'graph/showX1GridLines',
  locales: '',
  type: 'checkbox',
  options: {
    sv: { yes: 'Ja', no: 'Nej' },
    en: { yes: 'Yes', no: 'No' },
  },
};
const showY1GridLines: Setting = {
  id: 'graph/showY1GridLines',
  locales: '',
  type: 'checkbox',
  options: {
    sv: { yes: 'Ja', no: 'Nej' },
    en: { yes: 'Yes', no: 'No' },
  },
};
const showY2GridLines: Setting = {
  id: 'graph/showY2GridLines',
  locales: '',
  type: 'checkbox',
  options: {
    sv: { yes: 'Ja', no: 'Nej' },
    en: { yes: 'Yes', no: 'No' },
  },
};

const graphOptions = [
  showX1,
  showY1,
  showY2,
  showChartTitle,
  showChartLegend,
  showX1Label,
  showY1Label,
  showY2Label,
  showX1GridLines,
  showY1GridLines,
  // showY2GridLines,
];

const graphOptionsGroup = [
  { id: 'settings/group/data', options: [showX1, showY1, showY2] },
  { id: 'settings/group/titles', options: [showChartTitle, showChartLegend] },

  {
    id: 'settings/group/labels',
    options: [showX1Label, showY1Label, showY2Label],
  },
  {
    id: 'settings/group/grindlines',
    options: [
      showX1GridLines,
      showY1GridLines,
      // showY2GridLines,
    ],
  },
];

const defaultGraphOptions = [
  { 'graph/showFlow': 'yes' },
  { 'graph/showPressure': 'yes' },
  { 'graph/showVolume': 'yes' },
  { 'graph/showChartTitle': 'yes' },
  { 'graph/showChartLegend': 'yes' },
  { 'graph/showX1Label': 'yes' },
  { 'graph/showY1Label': 'yes' },
  { 'graph/showY2Label': 'yes' },
  { 'graph/showX1GridLines': 'yes' },
  { 'graph/showY1GridLines': 'yes' },
  // { 'graph/showY2GridLines': 'no' },
];

const defualtTemplateOptions = [
  { 'template/chartTitle': 'Hole - ${holeNo} ' },
  {
    'template/quillRoot': {
      ops: [
        {
          insert: {
            mention: {
              index: '4',
              denotationChar: '@',
              id: 'holeNo',
              value: 'Hole ',
            },
          },
        },
        { insert: ' \n' },
      ],
    },
  },
];

/**
 * Colors
 */
const flowColor: ColorSetting = {
  id: 'color/flow',
  locales: '',
  type: 'color',
};
const pressureColor: ColorSetting = {
  id: 'color/pressure',
  locales: '',
  type: 'color',
};

const volumeColor: ColorSetting = {
  id: 'color/volume',
  locales: '',
  type: 'color',
};

const colorOptions = [flowColor, pressureColor, volumeColor];
const defaultColorOptions = [
  { 'color/flow': '#ed4c78' },
  { 'color/pressure': '#00c9a7' },
  { 'color/volume': '#377dff' },
];
export {
  // General
  generalOptions,
  defaultGeneralOptions,
  // Graphs
  graphOptions,
  graphOptionsGroup,
  defaultGraphOptions,
  defualtTemplateOptions,
  // Colors
  colorOptions,
  defaultColorOptions,
};
