import { Component, OnInit } from '@angular/core';
/**
 * Ng2-Charts
 */
import { ViewChildren, QueryList } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
/**
 * Settings
 */
import { SettingsService } from 'src/app/services/settings/settings.service';
import { checkFile } from 'src/check-file/check-file';
import { Location } from '@angular/common';
import { readGroutFile } from 'src/assets/grout/grout.module.js';
import * as HSStickyBlock from 'src/assets/_theme/vendor/hs-sticky-block/dist/hs-sticky-block.min.js';
/**
 * Interfaces
 */
import {
  GroutingLog,
  Hole,
  OperatingLog,
  OperatingLogAttributes,
  OperatingLogs,
} from 'src/grout-js/grout-js';
// import { Chart } from 'chart.js';
import { initGraphs } from 'src/graphs/init/init';
import { updateGraphsSelector } from 'src/graphs/estimate-aperutre/graphs-selector';
import * as withinviewport from 'withinviewport';
/**
 * JSZip
 */
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
/**
 * jQuery
 */
declare const $: any;
@Component({
  selector: 'app-graphs',
  templateUrl: './graphs.component.html',
  styleUrls: ['./graphs.component.scss'],
})
export class GraphsComponent implements OnInit {
  constructor(
    private settingsService: SettingsService,
    private _location: Location
  ) {}
  /**
   * Settings
   */
  public s = this.settingsService.settings;
  /**
   * Check if file is loaded, otherwise show upload window (again)
   */
  public fileLoaded: boolean;
  /**
   * Chart.js Scatter Charts
   */
  @ViewChildren(BaseChartDirective) ng2Charts: QueryList<BaseChartDirective>;
  public graphs: any = {};
  public graphsArray: any = [];
  /**
   * Holes - based on Grouting Graphs
   */
  public holes: number[] = [];
  public min: number;
  public max: number;
  /**
   * Pages to be used
   */
  public inputPages: boolean | undefined;
  /**
   * Scrolling
   */
  requestScroll: boolean = true;
  ngOnInit(): void {
    this.fileLoaded = this.settingsService.event ? true : false;
    if (this.fileLoaded) this.readGroutFile();
  }
  /**
   * Check file on file change
   */
  fileChangeEvent(event: Event): void {
    if (checkFile(event) === false) {
      $('#errorModal').modal('toggle');
      return;
    }
    this.settingsService.event = event;
    this.readGroutFile();
  }
  /**
   * Read Grout File with Callback function
   */
  readGroutFile(): void {
    const event: Event = this.settingsService.event;
    const cb = (groutFile: any) => {
      this.graphsArray = initGraphs(groutFile, this.s);

      this.graphsArray.forEach((graph) => {
        this.graphs[graph.number] = graph;
        this.holes.push(+graph.number);
      });
      this.min = Math.min(...this.holes);
      this.max = Math.max(...this.holes);

      this.forceGraphs();
    };

    readGroutFile(event, cb);
  }
  /**
   * Force Angular to render graphs
   */
  forceGraphs() {
    const _this = this;
    this.fileLoaded = true;
    /**
     * Wait 100ms for elements to be drawn
     */
    setTimeout(function () {
      /**
       * Sidebar Range Slider
       */
      $('.js-range-slider').ionRangeSlider({
        values: _this.holes,
        onStart: function (data) {
          $('#rangeSliderResult').text(data.from);
        },
        onChange: function (data) {
          /**
           * Disable scrolling
           */
          _this.requestScroll = false;
          $('#rangeSliderResult').text(data.from);
          const id = (+data.from - 1).toString();
          const element = document.getElementById(id);
          if (element) element.scrollIntoView();
        },
        onFinish: function (data) {
          /**
           * Enable scrolling
           */
          _this.requestScroll = true;
        },
      });
      /**
       * Sticky Block Plugin
       */
      $('.js-sticky-block').each(function () {
        const stickyBlock = new HSStickyBlock($(this)).init();
      });
      /**
       * Scrolling
       */
      document.addEventListener(
        'wheel',
        function (e) {
          $('.graph')
            .withinviewport({ sides: 'right' })
            .each(function () {
              if (_this.requestScroll === false) return;
              const value = $(this).children()[0].getAttribute('data-number');
              $('.js-range-slider').data('ionRangeSlider').update({
                from: value,
              });
            });
        },
        {
          passive: true,
        }
      );
    }, 100);
  }
  /**
   * Go Back to prevouis page
   */
  back(): void {
    this._location.back();
  }

  /**
   * Borrowed from Chromium
   *
   * https://github.com/chromium/chromium/blob/master/chrome/browser/resources/print_preview/ui/pages_settings.html
   * https://github.com/chromium/chromium/blob/master/chrome/browser/resources/print_preview/ui/pages_settings.js
   *
   * More specific: updatePagesToPrint()
   * https://github.com/chromium/chromium/blob/8161e2e2ae9a802329cc24bcab3550d6cf6ca5c0/chrome/browser/resources/print_preview/ui/pages_settings.js#L184
   */
  onChange(event) {
    const inputString: string = event.target.value;
    const pages: number[] | undefined = updateGraphsSelector(
      inputString,
      this.max
    );
    this.inputPages = pages ? true : false;
  }
  exportGraphs() {
    const folderName = 'rock-grouting-tookit';
    const zip = new JSZip();
    const folder = zip.folder(folderName);
    this.ng2Charts.forEach((ng2Chart) => {
      const { chart } = ng2Chart;
      const chartId = chart['id'];
      const path = `graph-${chartId}.png`;
      const data = ng2Chart.toBase64Image().substring(22); // Remove "data:image/png;base64"
      const options = { base64: true };
      folder.file(path, data, options);
    });
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, 'groupt.zip');
    });
  }
}
