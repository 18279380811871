import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SettingsService } from 'src/app/services/settings/settings.service';
// import { dataSets } from 'src/graphs/chart-js/data/data-sets';
// import { chartOptions } from 'src/graphs/chart-js/options/options';
// import { ChartDataSets, ChartType, ChartOptions } from 'chart.js';
import { OperatingLogs, Hole } from 'src/grout-js/grout-js';
import { dummyHole } from 'src/grout-js/dumy-data';
import { initGraphs } from 'src/graphs/init/init';
import { QuillEditorComponent } from 'ngx-quill';
import { ColorEvent, Color } from 'ngx-color';
import * as HSStickyBlock from 'src/assets/_theme/vendor/hs-sticky-block/dist/hs-sticky-block.min.js';
import { BaseChartDirective } from 'ng2-charts/';

/**
 * jQuery
 */
declare const $: any;
declare const HSToggleState: any;
@Component({
  selector: 'app-settings-graph',
  templateUrl: './settings-graph.component.html',
  styleUrls: ['./settings-graph.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class SettingsGraphComponent implements OnInit {
  constructor(
    private settingsService: SettingsService,
    private _eref: ElementRef
  ) {}
  @ViewChild('colorPicker') colorPicker;
  @ViewChild('colorPickerToggle') colorPickerToggle;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  /**
   * Settings
   */
  public s = this.settingsService.settings;
  public settings = this.s.graphOptions;
  public settingsGroups = this.s.graphOptionsGroup;
  public colorSettings = this.s.colorOptions;
  /**
   * Toggle All Target Selector
   */
  public targetSelector: string;
  /**
   * Dummy Data for the Chart
   */
  private dummyHole: Hole = dummyHole;
  /**
   * Property for Graphs.
   * The settings page only holds a single graph.
   */
  public graphs;
  /**
   * Template Enginge
   *
   * Quill.js + Mentions
   */
  // @ViewChild(QuillEditorComponent, { static: true })
  editor: QuillEditorComponent;
  modules = {
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      source: (searchTerm, renderList) => {
        const values = [
          {
            id: 'project',
            value: this.s.getLocale('settings/template/project'),
          }, // Projekt
          { id: 'tunnel', value: this.s.getLocale('settings/template/tunnel') },
          { id: 'pegNo', value: this.s.getLocale('settings/template/pegNo') }, // Sektion
          {
            id: 'screenNo',
            value: this.s.getLocale('settings/template/screenNo'),
          }, // Omgång
          { id: 'holeNo', value: this.s.getLocale('settings/template/holeNo') }, // Hål
          { id: 'pumpNr', value: this.s.getLocale('settings/template/pumpNr') }, // Pump
        ];

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];

          values.forEach((entry) => {
            if (
              entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            ) {
              matches.push(entry);
            }
          });
          renderList(matches, searchTerm);
        }
      },
    },
    toolbar: false,
  };
  templateStrings = {
    project: this.s.getLocale('settings/template/project/placeholder'),
    tunnel: this.s.getLocale('settings/template/tunnel/placeholder'),
    pegNo: this.s.getLocale('settings/template/pegNo/placeholder'),
    screenNo: this.s.getLocale('settings/template/screenNo/placeholder'),
    holeNo: this.s.getLocale('settings/template/holeNo/placeholder'),
    pumpNr: this.s.getLocale('settings/template/pumpNr/placeholder'),
  };
  dev: string;
  /**
   * Color Picker
   */
  public colorPickers = {};
  public showColorPicker: boolean = false;
  public useSimpleColorPicker: boolean = true;
  public flowColor: Color = {
    hex: '#eb144c',
    hsl: { h: 344.3720930232558, s: 0.8431372549019607, l: 0.5, a: 1 },
    hsv: {
      h: 344.3720930232558,
      s: 0.9148936170212766,
      v: 0.9215686274509803,
      a: 1,
    },
    oldHue: 250,
    rgb: { r: 235, g: 20, b: 76, a: 1 },
    source: 'hex',
  };
  public pressureColor: Color;
  public volumeColor: Color;
  ngOnInit(): void {
    const _this = this;

    this.colorSettings.forEach((colorSetting) => {
      this.colorPickers[colorSetting.id] = {
        visible: false,
      };
    });

    /**
     * Toggle All
     */
    const targetSelector = _this.settings
      .map((setting) => `#${setting.id}`)
      .toString();
    this.targetSelector = `{"targetSelector": "${targetSelector}"}`;

    _this.generateSettingsGraph();
    _this.updateLocales();

    $(function () {
      $('.js-sticky-block').each(function () {
        var stickyBlock = new HSStickyBlock($(this)).init();
      });
      $('.js-toggle-state').each(function () {
        var toggleState = new HSToggleState($(this)).init();
      });
      $('.js-custom-select').each(function () {
        const select2 = $.HSCore.components.HSSelect2.init($(this));
        select2.on('select2:select', (e) => {
          const key = e.target.id;
          const value = e.target.value;

          // const key = e.params.data.element.parentElement.id;
          // const value = e.params.data.id;
          // _this.s.setOption(key, value); ????
        });
      });
    });
  }
  inputRadioChange(event) {
    const key = event.target.name;
    // const value = event.target.value;
    const value = event.target.checked ? 'yes' : 'no';

    this.s.setOption(key, value);
    this.updateLocales();
    this.generateSettingsGraph();
  }
  toggleAll(event) {
    const value: string = event.target.id === 'toggle-all' ? 'yes' : 'no';
    this.settings.forEach((setting) => {
      const { id: key } = setting;
      this.s.setOption(key, value);
    });
    this.updateLocales();
    this.generateSettingsGraph();
  }

  updateLocales(): void {
    this.s.graphOptions.forEach((option) => {
      option.locales = this.s.getSettingsLocale(option.id);
    });
  }
  /**
   * The settings page will feature only a single graph with dummy data
   */
  generateSettingsGraph() {
    const operatingLogs: OperatingLogs = {
      '0': this.dummyHole,
    };
    const groutFile = {
      metadata: {
        Project: this.templateStrings.project,
        Tunnel: this.templateStrings.tunnel,
        PegNo: this.templateStrings.pegNo,
        ScreenNo: this.templateStrings.screenNo,
      },
      operatingLogs: operatingLogs,
    };

    const graphs = initGraphs(groutFile, this.s);
    this.graphs = graphs;
  }

  /**
   * Template Engine
   */
  onEditorChanged(event) {
    const editor = event.editor;
    if (editor === undefined) return;
    const innerHTML = editor.root.innerHTML;
    const parser = new DOMParser();
    const doc = parser.parseFromString(innerHTML, 'text/html');
    const p = doc.getElementsByTagName('p')[0];
    const childNodes = p.childNodes;
    const children = p.children;

    let text = '';
    let k = 0;
    for (let index = 0; index < childNodes.length; index++) {
      const childNode = childNodes[index];

      if (childNode.nodeName === '#text') {
        text += childNode.nodeValue;
      } else {
        const dataId = children[k].getAttribute('data-id');
        if (dataId === null) continue;

        text += '${' + dataId + '}';
        k++;
      }
    }
    let dummyString = text;

    Object.keys(this.templateStrings).forEach((key) => {
      dummyString = dummyString.replace(
        '${' + key + '}',
        this.templateStrings[key]
      );
    });
    this.dev = dummyString;

    this.graphs[0].options.title.text = dummyString;
    this.graphs[0].options = { ...this.graphs[0].options };

    this.s.setOption('template/chartTitle', text);
    const quillRoot = editor.getContents();
    this.s.setOption('template/quillRoot', quillRoot);
  }
  onEditorCreated(editor) {
    const contents = this.s.getOption('template/quillRoot');
    editor.setContents(contents);
  }
  onClick(event) {
    // if (this.showColorPicker === false) return;
    if (event.target.id === 'toggleColorPicker') return;
    if (this.colorPicker === undefined) return;
    if (this.colorPicker.nativeElement.contains(event.target)) return;
    Object.values(this.colorPickers).forEach((colorPicker: any) => {
      colorPicker.visible = false;
    });
  }

  /**
   * Colors
   */
  getColor(id) {
    return this.s.getOption(id);
  }
  handleClickEvent(id) {
    const _this = this;
    setTimeout(function () {
      _this.colorPickers[id].visible = true;
    }, 1);
  }
  toggleSimpleColorPicker(bool) {
    this.useSimpleColorPicker = bool;
  }
  // handleChangeComplete(event: ColorEvent) {
  //   console.log(event.color);
  // }
  handleChange(event: ColorEvent, id: string) {
    const hex = event.color.hex;
    this.s.setOption(id, hex);
    const data = this.graphs[0].data.map((data) => {
      console.log(data);

      const { label } = data;
      if (label.toLowerCase() !== id.split('/')[1].toLowerCase()) return data;

      data = {
        ...data,
        backgroundColor: hex,
        borderColor: hex,
        pointBorderColor: hex,
      };

      return data;
    });
    this.graphs[0].data = data;

    this.chart.chart.update();
  }
}
