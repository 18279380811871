<!-- General Settings -->
<div>
  <!-- Card -->
  <div class="card">
    <div class="card-header bg-primary">
      <h5 class="card-title text-white">
        {{ s.getLocale("settings/general") }}
      </h5>
    </div>

    <!-- Body -->
    <div class="card-body">
      <!-- <p class="card-text">
        Use our template maker to create a custom title or axis lable. Use the
        "@" symbol to select a template.
      </p> -->
      <!-- Form -->
      <form>
        <div *ngFor="let setting of settings">
          <!-- Form Group -->
          <div class="row form-group">
            <label
              for="currentPasswordLabel"
              class="col-sm-3 col-form-label input-label"
              >{{ setting.locales }}</label
            >

            <div class="col-sm-9">
              <!-- Option  -->
              <div [ngSwitch]="setting.type">
                <!-- Radio -->
                <div *ngSwitchCase="'radio'">
                  <div class="input-group input-group-md-down-break">
                    <!-- Radio Option -->
                    <div
                      *ngFor="
                        let option of setting.options[s.getLanguage()]
                          | keyvalue
                      "
                    >
                      <div class="form-control">
                        <div class="custom-control custom-radio">
                          <input
                            (change)="inputRadioChange($event)"
                            type="radio"
                            class="custom-control-input"
                            name="{{ setting.id }}"
                            id="{{ setting.id }}{{ option.key }}"
                            value="{{ option.key }}"
                            [attr.checked]="
                              s.getOption(setting.id) === option.key ? '' : null
                            "
                          />
                          <label
                            class="custom-control-label"
                            for="{{ setting.id }}{{ option.key }}"
                            >{{ option.value }}</label
                          >
                        </div>
                      </div>
                    </div>
                    <!-- End Radio Option -->
                  </div>
                </div>
                <!-- End Radio -->
                <!-- Select2 -->
                <div *ngSwitchCase="'select'">
                  <select
                    id="{{ setting.id }}"
                    class="js-custom-select custom-select"
                    size="1"
                    style="opacity: 0"
                    data-hs-select2-options='{
             "minimumResultsForSearch": "Infinity"
           }'
                  >
                    <!-- Select2 Option -->
                    <option
                      *ngFor="
                        let option of setting.options[s.getLanguage()]
                          | keyvalue
                      "
                      [attr.data-option-template]="
                        getOptionTemplate(setting.id, option.key, option.value)
                      "
                      value="{{ option.key }}"
                      [attr.selected]="
                        s.getOption(setting.id) === option.key ? '' : null
                      "
                    >
                      {{ option.value }}
                    </option>

                    <!-- End Select2 Option -->
                  </select>
                </div>
                <!-- End Select2 -->
              </div>
              <!-- End Option  -->
            </div>
          </div>
          <!-- End Form Group -->
        </div>
      </form>
      <!-- End Form -->
    </div>
    <!-- End Body -->
  </div>
  <!-- End Card -->
</div>
<!-- End Advanced Settings -->
