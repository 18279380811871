<!-- ========== HEADER ========== -->
<app-header></app-header>
<!-- ========== END HEADER ========== -->
<!-- ========== HOME ========== -->
<div class="bg-light">
  <div class="container space-md-2 space-lg-4">
    <div class="text-center mx-lg-auto">
      <div class="mb-5">
        <h1 class="display-4 mb-3">
          Rock Grouting <span class="text-primary">Toolkit</span>
        </h1>
        <p class="lead">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique
          unde ab obcaecati fuga, nemo molestias numquam tempore animi,
          molestiae accusamus ullam error, vitae aut libero culpa vel. Sed,
          harum quia.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <a
          class="card card-frame"
          data-container="body"
          data-toggle="popover"
          data-placement="top"
          data-trigger="hover"
          data-delay='{ "show": 1000, "hide": 0 }'
          [attr.title]="s.getLocale('hero/view-graphs/popover/title')"
          [attr.data-content]="s.getLocale('hero/view-graphs/popover/content')"
        >
          <div class="card-body">
            <div class="border-bottom mb-4">
              <h3 class="">
                <span class="text-primary">
                  {{ s.getLocale("hero/view-graphs") }}</span
                >
                {{ s.getLocale("hero/and") }}
                <span class="text-primary">{{
                  s.getLocale("hero/estimate-aperture")
                }}</span>
              </h3>
            </div>
            <!-- File Attachment Input -->
            <div class="">
              <label class="file-attachment-input bg-light" for="ifcFileInput">
                <span id="customFileExample4 ">
                  <div class="h4">
                    {{ s.getLocale("hero/click") }}
                    <span
                      class="text-primary text-highlight-primary font-weight-bold"
                      >{{ s.getLocale("hero/here") }}</span
                    >
                    {{ s.getLocale("hero/select-xml-file") }}
                  </div>
                </span>

                <input
                  (change)="fileChangeEvent($event)"
                  id="ifcFileInput"
                  name="file-attachment"
                  type="file"
                  class="js-file-attach file-attachment-input-label"
                />
              </label>
            </div>
            <!-- End File Attachment Input -->
          </div>
        </a>
      </div>

      <div class="d-flex align-items-center mx-2">
        <span class="">{{ s.getLocale("hero/or") }}</span>
      </div>

      <div class="col">
        <a
          class="card card-frame"
          data-container="body"
          data-toggle="popover"
          data-placement="top"
          data-trigger="hover"
          data-delay='{ "show": 1000, "hide": 0 }'
          [attr.title]="s.getLocale('hero/estimate-spread/popover/title')"
          [attr.data-content]="
            s.getLocale('hero/estimate-spread/popover/content')
          "
        >
          <div class="card-body">
            <div class="border-bottom mb-4">
              <h3 class="">
                <span class="text-primary">{{
                  s.getLocale("hero/estimate-spread")
                }}</span>
              </h3>
            </div>
            <div class="text-center">
              <a class="btn btn-primary btn-block transition-3d-hover" href="#"
                >{{ s.getLocale("hero/default-settings") }}
              </a>

              <small class="text-muted py-4 mx-2 mx-sm-3">{{
                s.getLocale("hero/or")
              }}</small>
              <a
                class="btn btn-soft-primary btn-block transition-3d-hover"
                href="#"
                >{{ s.getLocale("hero/custom-settings") }}</a
              >
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
<!-- ========== END HOME ========== -->
<!-- ========== FOOTER ========== -->
<app-footer></app-footer>
<!-- ========== END FOOTER ========== -->
<!-- ========== MODALS ========== -->
<app-error></app-error>
<!-- ========== END MODALS ========== -->

<!-- ========== DEV ========== -->
<!-- Error Modal Trigger -->
<a
  class="btn btn-ghost-primary transition-3d-hover"
  href="javascript:;"
  data-toggle="modal"
  data-target="#errorModal"
  >Debug File Error</a
>
<!-- Error End Modal Trigger -->
<!-- ========== END DEV ========== -->
