<!-- ========== PRELOADER ========== -->
<div id="jsPreloader" class="page-preloader">
  <div class="page-preloader-content-centered">
    <div class="spinner-grow text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
<!-- ========== END PRELOADER ========== -->

<!-- ========== HEADER ========== -->
<!-- Breadcrumb Section -->
<div
  class="bg-dark"
  style="
    background-image: url(/assets/_theme/svg/components/abstract-shapes-20.svg);
  "
>
  <div
    *ngIf="
      fileLoaded;
      then breadcrumbFileUploaded;
      else breadcrumbFileNotUploaded
    "
  ></div>
  <ng-template #breadcrumbFileNotUploaded>
    <div class="container space-1 space-lg-2">
      <div class="row align-items-center">
        <div class="col">
          <div class="d-none d-lg-block">
            <h1 class="h2 text-white">
              Rock Grouting
              <span class="text-danger font-weight-bold">Toolkit</span>
            </h1>
          </div>

          <!-- Breadcrumb -->
          <!-- <ol class="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
            <li class="breadcrumb-item active">Upload File</li>
          </ol> -->
          <!-- End Breadcrumb -->
        </div>
        <div class="col-auto">
          <div class="d-none d-lg-block">
            <!-- Unfold (Sidebar) -->
            <div class="hs-unfold">
              <a
                id="sidebar-open-button"
                class="js-hs-unfold-invoker btn btn-outline-light mx-1"
                href="javascript:;"
                data-hs-unfold-options='{
      "target": "#sidebarContent",
      "type": "css-animation",
      "animationIn": "fadeInRight",
      "animationOut": "fadeOutRight",
      "hasOverlay": "rgba(55, 125, 255, 0.1)",
      "smartPositionOff": true
     }'
                >Settings</a
              >
            </div>
            <!-- End Unfold (Sidebar) -->
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #breadcrumbFileUploaded>
    <div class="container space-1 space-lg-2">
      <div class="row align-items-center">
        <div class="col">
          <div class="d-none d-lg-block">
            <h1 class="h2 text-white">View Grouting Graphs</h1>
          </div>

          <!-- Breadcrumb -->
          <ol class="breadcrumb breadcrumb-light breadcrumb-no-gutter mb-0">
            <li class="breadcrumb-item">Upload File</li>
            <li class="breadcrumb-item active" aria-current="page">
              View Data
            </li>
          </ol>
          <!-- End Breadcrumb -->
        </div>

        <div class="col-auto">
          <div class="d-none d-lg-block">
            <a class="btn btn-soft-light disabled mx-1" href="#">View Graphs</a>
            <a class="btn btn-soft-light mx-1" href="#">Estimate Aparture</a>
            <a class="btn btn-soft-light mx-1" href="#">Estimate Penetration</a>

            <a
              id="sidebar-open-button-2"
              class="js-hs-unfold-invoker btn btn-outline-light mx-1"
              href="javascript:;"
              data-hs-unfold-options='{
      "target": "#sidebarContent",
      "type": "css-animation",
      "animationIn": "fadeInRight",
      "animationOut": "fadeOutRight",
      "hasOverlay": "rgba(55, 125, 255, 0.1)",
      "smartPositionOff": true
     }'
              >Settings</a
            >
          </div>
          <div class="d-block d-sm-none mr-4">
            <a class="btn btn-soft-light disabled btn-block my-1" href="#"
              >View Graphs</a
            >
            <a class="btn btn-soft-light btn-block my-1" href="#"
              >Estimate Aparture</a
            >
            <a class="btn btn-soft-light btn-block my-1" href="#"
              >Estimate Penetration</a
            >
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<!-- End Breadcrumb Section -->
<!-- ========== END HEADER ========== -->

<!-- ========== MAIN CONTENTS ========== -->
<div>
  <!-- File Attachment Input -->
  <div [hidden]="fileLoaded" class="container">
    <div class="space-2">
      <label class="file-attachment-input bg-light" for="ifcFileInput">
        <span id="customFileExample4">
          <figure class="max-w-10rem mx-auto mb-3">
            <i class="fa-5x far fa-file-alt"></i>
          </figure>
          <div class="h4 mt-4">
            Click
            <span class="text-danger text-highlight-danger font-weight-bold"
              >here</span
            >
            to select an XML file
          </div>
        </span>

        <input
          (change)="onChange($event)"
          id="ifcFileInput"
          name="file-attachment"
          type="file"
          class="js-file-attach file-attachment-input-label"
          data-hs-file-attach-options='{
"maxFileSize": "infinity"
}'
        />
      </label>
    </div>
  </div>
  <!-- End File Attachment Input -->
  <!-- App -->
  <div [hidden]="!fileLoaded" class="bg-light space-2">
    <div class="container">
      <div class="row">
        <!-- Sidebar -->
        <div class="col-lg-4">
          <!-- Sticky Block Start Tag -->
          <div id="stickyBlockStartPoint" class=""></div>
          <!-- Sidebar Content -->
          <div
            class="js-sticky-block"
            data-hs-sticky-block-options='{
             "parentSelector": "#stickyBlockStartPoint",
             "startPoint": "#stickyBlockStartPoint",
             "endPoint": "#stickyBlockEndPoint",
             "stickyOffsetTop": 50,
             "stickyOffsetBottom": 0
           }'
          >
            <!-- Card -->
            <div class="card bg-white">
              <div class="card-header text-center py-5">
                <div class="w-100 max-w-27rem mx-auto">
                  <h2 class="">Log {{ operatingLog.number }}</h2>
                </div>
              </div>
              <div class="card-body">
                <div class="border-bottom pb-2 mb-4">
                  <dl class="row font-size-1">
                    <dt class="col-sm-4 text-dark">No. holes</dt>
                    <dd class="col-sm-8 text-body">43</dd>
                  </dl>
                  <dl class="row font-size-1">
                    <dt class="col-sm-4 text-dark">Tot. volume</dt>
                    <dd class="col-sm-8 text-body">
                      {{ operatingLog.info.totalFlow }}
                    </dd>
                  </dl>
                  <dl class="row font-size-1">
                    <dt class="col-sm-4 text-dark">Tot. pressure</dt>
                    <dd class="col-sm-8 text-body">
                      {{ operatingLog.info.totalPressure }}
                    </dd>
                  </dl>
                  <dl class="row font-size-1">
                    <dt class="col-sm-4 text-dark">Tot. volume</dt>
                    <dd class="col-sm-8 text-body">
                      {{ operatingLog.info.totalVolume }}
                    </dd>
                  </dl>
                </div>
                <div class="border-bottom pb-4 mb-4">
                  <h4>Hole</h4>

                  <!-- Range Slider -->
                  <div class="mt-10">
                    <input
                      class="js-ion-range-slider"
                      type="text"
                      data-hs-ion-range-slider-options='{
              "extra_classes": "range-slider-custom range-slider-custom-grid",
                "grid": true,
              "hide_from_to": false,
              "min": 0,
              "max": 100,
              "from": 0
            }'
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- End Card -->
            <!-- Help -->
            <div
              id="shopCartAccordionExample1"
              class="accordion card shadow-soft my-4"
            >
              <!-- Card -->
              <div class="card">
                <div
                  class="card-header card-collapse"
                  id="shopCartHeadingOneExample2"
                >
                  <h3 class="mb-0">
                    <a
                      class="btn btn-link btn-block card-btn font-weight-bold collapsed"
                      href="javascript:;"
                      role="button"
                      data-toggle="collapse"
                      data-target="#shopCartOneExample2"
                      aria-expanded="false"
                      aria-controls="shopCartOneExample2"
                    >
                      Help
                      <i class="far fa-question-circle text-body ml-1"></i>
                    </a>
                  </h3>
                </div>
                <div
                  id="shopCartOneExample2"
                  class="collapse"
                  aria-labelledby="shopCartHeadingOneExample2"
                  data-parent="#shopCartAccordionExample1"
                >
                  <form class="js-validate p-4">
                    <div class="input-group input-group-pill mb-3">
                      <input
                        type="text"
                        class="form-control"
                        name="name"
                        placeholder="Promo code"
                        aria-label="Promo code"
                      />
                      <div class="input-group-append">
                        <button
                          type="submit"
                          class="btn btn-block btn-primary btn-pill"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <!-- End Card -->
            </div>
            <!-- End Help -->
          </div>
          <!-- End Sidebar Content -->
        </div>
        <!-- End Sidebar Section -->
        <div class="col-lg-8">
          <div class="card">
            <div
              class="card-header bg-img-hero text-center py-5"
              style="
                background-image: url(/assets/_theme/svg/components/abstract-shapes-21.svg);
              "
            >
              <div class="text-left flex-grow-1">
                <h3 class="card-header-title">Getting started</h3>
              </div>
            </div>
            <div class="card-body bg-white">
              <div class="graphs">
                <div class="graph" *ngFor="let operatingLog of operatingLogs">
                  <div
                    id="{{ 'canvas-' + operatingLog.number }}"
                    data-number="{{ operatingLog.number }}"
                  >
                    <canvas
                      baseChart
                      #myChart="base-chart"
                      [id]="operatingLog.number"
                      [datasets]="operatingLog.data"
                      [options]="operatingLog.chartOptions"
                      [chartType]="chartType"
                    >
                    </canvas>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Sticky Block End Point -->
      <div id="stickyBlockEndPoint"></div>
    </div>
  </div>
  <!-- End App -->

  <a
    class="js-go-to go-to position-fixed"
    href="#"
    style="visibility: hidden"
    data-hs-go-to-options='{
     "offsetTop": 700,
     "position": {
       "init": {
         "right": 15
       },
       "show": {
         "bottom": 15
       },
       "hide": {
         "bottom": -15
       }
     }
   }'
  >
    <i class="fas fa-arrow-up"></i>
  </a>
</div>
<!-- ========== END MAIN CONTENTS ========== -->

<!-- ========== FOOTER ========== -->
<footer class="container text-center space-1">
  <!-- Logo -->
  <a class="d-inline-flex align-items-center mb-2" href="#" aria-label="Front">
    <img
      width="30%"
      style="margin: auto"
      src="../assets/svg/logos/logo.svg"
      alt="Rock Grouting Toolkit"
    />
  </a>
  <!-- End Logo -->

  <p class="small text-muted mb-0">
    Created by
    <a
      href="http://linkedin.com/in/andrewisen/"
      target="_blank"
      rel="noopener noreferrer"
      >André Wisén</a
    >
    &amp;
    <a
      href="https://www.linkedin.com/in/louisesjolund/"
      target="_blank"
      rel="noopener noreferrer"
      >Louise Sjölund</a
    >
  </p>
</footer>
<!-- ========== END FOOTER ========== -->

<!-- ========== SECONDARY CONTENTS ========== -->
<!-- Sidebar Navigation -->
<aside id="sidebarContent" class="hs-unfold-content sidebar">
  <div class="sidebar-scroller">
    <div class="sidebar-container">
      <div class="sidebar-footer-offset" style="padding-bottom: 7rem">
        <!-- Toggle Button -->
        <div class="d-flex justify-content-end align-items-center pt-4 px-4">
          <div class="hs-unfold">
            <a
              id="sidebar-close-button"
              class="js-hs-unfold-invoker btn btn-icon btn-xs btn-soft-secondary"
              href="javascript:;"
              data-hs-unfold-options='{
                "target": "#sidebarContent",
                "type": "css-animation",
                "animationIn": "fadeInRight",
                "animationOut": "fadeOutRight",
                "hasOverlay": "rgba(55, 125, 255, 0.1)",
                "smartPositionOff": true
               }'
            >
              <svg
                width="10"
                height="10"
                viewBox="0 0 18 18"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="currentColor"
                  d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"
                />
              </svg>
            </a>
          </div>
        </div>
        <!-- End Toggle Button -->

        <!-- Content -->
        <div class="scrollbar sidebar-body">
          <div class="sidebar-content py-4 px-7">
            <h4 class="h5">{{ locale[storage.language]["settings"] }}</h4>
            <!-- ========== SETTINGS ========== -->
            <div class="container">
              <!-- Settings -->
              <div *ngFor="let settings of appSettings">
                <div>
                  {{ locale[storage.language][settings.id] }}
                  <select
                    id="{{ settings.id }}"
                    class="js-custom-select custom-select"
                    size="1"
                    style="opacity: 0"
                    data-hs-select2-options='{
          "minimumResultsForSearch": "Infinity"
        }'
                  >
                    <option
                      *ngFor="
                        let option of settings.options[storage.language]
                          | keyvalue
                      "
                      value="{{ option.key }}"
                      [attr.selected]="
                        storage[settings.id] === option.key ? '' : null
                      "
                    >
                      {{ option.value }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- End Settings -->
              <!-- Quill -->
              <div>
                <span>{{ locale[storage.language]["graph/title"] }}</span>

                <quill-editor
                  [modules]="modules"
                  (onEditorChanged)="foo($event)"
                  (onEditorCreated)="bar($event)"
                >
                </quill-editor>

                <div
                  class="alert alert-soft-danger alert-dismissible fade show"
                  role="alert"
                >
                  <strong>{{
                    locale[storage.language]["graph/title/hint"]
                  }}</strong>
                  {{ locale[storage.language]["graph/title/hint/text"] }}
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <svg
                      aria-hidden="true"
                      class="mb-0"
                      width="14"
                      height="14"
                      viewBox="0 0 18 18"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="currentColor"
                        d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"
                      />
                    </svg>
                  </button>
                </div>

                <canvas
                  baseChart
                  #myChart="base-chart"
                  [title]="'Walla ab'"
                  [id]="'setting'"
                  [datasets]="settingsChart.datasets"
                  [options]="settingsChart.options"
                  [chartType]="chartType"
                >
                </canvas>
              </div>
              <!-- End Quill -->
            </div>
            <!-- ========== SETTINGS ========== -->
          </div>
        </div>
        <!-- End Content -->
      </div>

      <!-- Footer -->
      <footer class="sidebar-footer border-top py-2 px-7">
        <ul class="nav nav-sm">
          <li class="nav-item">
            <a class="nav-link pl-0" href="#">Privacy</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Terms</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              <i class="fas fa-info-circle la-lg"></i>
            </a>
          </li>
        </ul>
      </footer>
      <!-- End Footer -->
    </div>
  </div>
</aside>
<!-- End Sidebar Navigation -->
<!-- ========== END SECONDARY CONTENTS ========== -->
