interface Values {
  [key: string]: { [key: string]: string };
}

const values: Values = {
  sv: {
    'rock-grouting': 'cementinjektering i berg',
    settings: 'Inställningar',
    'settings/example': 'Exempel',
    'settings/general': 'Allmänt',

    /**
     * General Settings
     */
    'settings/language': 'Språk',
    'settings/units': 'Måttenheter',
    'settings/color': 'Färg',
    'settings/colors': 'Färger',
    'settings/scale': 'Skala',
    'settings/reset': 'Återställ inställningar',
    'settings/reset/confirm':
      'Är du säker på att du vill återställa inställningarna?',
    /**
     * Setting Groups
     */
    'settings/group/data': 'Data',
    'settings/group/titles': 'Rubriker',
    'settings/group/labels': 'Axelrubriker',
    'settings/group/grindlines': 'Rutnät',
    /**
     * Graph Settings
     */
    'settings/graph': 'Grafinställningar',
    // Simple
    'settings/graph/simple': 'Diagram',
    'settings/graph/simple/tips':
      'Diagram innehåller flera element, till exempel en rubrik, axeletiketter, en förklaring och stödlinjer. Du kan dölja eller visa dessa element.',
    'settings/graph/show': 'Visa element',
    'settings/graph/toggle-all': 'Markera alla',
    'settings/graph/untoggle-all': 'Avmarkera alla',
    'settings/graph/showFlow': 'Flöde',
    'settings/graph/showPressure': 'Tryck',
    'settings/graph/showVolume': 'Volym',
    'settings/graph/showChartTitle': 'Diagramrubrik',
    'settings/graph/showChartLegend': 'Diagramförklaring',
    'settings/graph/showX1Label': 'Horisontell axel',
    'settings/graph/showY1Label': 'Primär vertikal axel',
    'settings/graph/showY2Label': 'Sekundär vertikal axel',
    'settings/graph/showX1GridLines': 'Horisontell axel',
    'settings/graph/showY1GridLines': 'Primär vertikal axel',
    'settings/graph/showY2GridLines': 'Sekundär vertikal axel',
    // Advanced
    'settings/graph/advanced': 'Anvancerat',
    'settings/graph/advanced/text':
      'Du kan ändra digramelementen i mer detalj.',
    'settings/graph/advanced/tips':
      'Använd "@"-symbolen för att hänvisa till en mall. E.g. "@tunnel" kommer retunera tunnels namn.',
    /**
     * Graph Settings Template
     *
     */
    'settings/template/project': 'Projekt',
    'settings/template/tunnel': 'Tunnel',
    'settings/template/pegNo': 'Sektion',
    'settings/template/screenNo': 'Omgång',
    'settings/template/holeNo': 'Hål',
    'settings/template/pumpNr': 'Pump',
    'settings/template/project/placeholder': 'Norra Tunnelprojektet',
    'settings/template/tunnel/placeholder': '10 Norrgående',
    'settings/template/pegNo/placeholder': '119675',
    'settings/template/screenNo/placeholder': '2',
    'settings/template/holeNo/placeholder': '41',
    'settings/template/pumpNr/placeholder': '2',
    /**
     * Colors
     */

    'settings/color/flow': 'Flöde',
    'settings/color/pressure': 'Tryck',
    'settings/color/volume': 'Volym',
    'settings/color/more': 'Fler färginställningar',
    'settings/color/less': 'Mindre färginställningar',
    'settings/color/hint': 'Tryck på cirklarna för att öppna färgväljaren.',
    /**
     * Chart.js Graphs
     */
    graph: 'Graf',
    'graph/title': 'Hål',
    'graph/title/hint': 'Tips:',
    'graph/title/hint/text': 'Använd @-symbolen för att välja en mall',
    'graph/label/x1': 'Tid [hh:mm]',
    'graph/label/y1': 'Tryck (bar) och flöde (l/min)',
    'graph/label/y2': 'Volym (liter)',
    'graph/legend/flow': 'Flöde',
    'graph/legend/pressure': 'Tryck',
    'graph/legend/volume': 'Volym',
    'graph/tooltip/x': 'Tid',
    hero: 'hjälte',
    'hero/view-graphs': 'Visa grafer',
    'hero/view-graphs/popover/title': 'Info',
    'hero/view-graphs/popover/content':
      'Använda detta verktyg för att visa injekteringsgrafer',
    'hero/and': 'och',
    'hero/estimate-aperture': 'uppskatta apertur',
    'hero/click': 'Tryck',
    'hero/here': 'här',
    'hero/select-xml-file': 'för att välja en XML fil',
    'hero/or': 'eller',
    'hero/estimate-spread': 'Uppskatta spridning',
    'hero/estimate-spread/popover/title': 'Info',
    'hero/estimate-spread/popover/content': 'Uppskatta sprdining',
    'hero/default-settings': 'Standardinställningar',
    'hero/custom-settings': 'Egna inställningar',
    back: 'Tillbaka',
    error: 'fel',
    'error/error': 'Åh nej!',
    'error/message': 'Något gick fel',
    'error/reason/file':
      'Rock Grouting Toolkit kunde inte ladda filen. Vi ber om ursäkt för besväret. Vänligen försök med en annan fil.',
    'error/contact': 'Om du har några frågor, kontakta oss på',
  },
  en: {
    'rock-grouting': 'rock grouting',
    settings: 'Settings',
    'settings/example': 'Example',
    'settings/general': 'General',
    /**
     * General Settings
     */
    'settings/language': 'Language',
    'settings/units': 'Units',
    'settings/color': 'Color',
    'settings/colors': 'Colors',
    'settings/scale': 'Scale',
    'settings/reset': 'Reset settings',
    'settings/reset/confirm':
      'Are you sure you want to reset settings to default?',
    /**
     * Setting Groups
     */
    'settings/group/data': 'Data',
    'settings/group/titles': 'Labels',
    'settings/group/labels': 'Axis labels',
    'settings/group/grindlines': 'Gridlines',
    /**
     * Graph Settings
     */
    'settings/graph': 'Graph settings',
    // Simple
    'settings/graph/simple': 'Graph',
    'settings/graph/simple/tips':
      'Charts contain several elements, such as a title, axis labels, a legend, and gridlines. You can hide or display these elements.',
    'settings/graph/show': 'Show elements',
    'settings/graph/toggle-all': 'Toggle all',
    'settings/graph/untoggle-all': 'Unoggle all',
    'settings/graph/showFlow': 'Flow',
    'settings/graph/showPressure': 'Pressure',
    'settings/graph/showVolume': 'Volume',
    'settings/graph/showChartTitle': 'Title',
    'settings/graph/showChartLegend': 'Legend',
    'settings/graph/showX1Label': 'Horizontal axis',
    'settings/graph/showY1Label': 'Primary vertical axis',
    'settings/graph/showY2Label': 'Secondary vertical axis',
    'settings/graph/showX1GridLines': 'Horizontal axis',
    'settings/graph/showY1GridLines': 'Primary vertical  ',
    'settings/graph/showY2GridLines': 'Secondary vertical',
    // Advanced
    'settings/graph/advanced': 'Advanced',
    'settings/graph/advanced/text':
      'You can change some of the chart elements in more detail.',
    'settings/graph/advanced/tips':
      'Use the "@" symbol to refer to a template. E.g. "@tunnel" will return the tunnel name.',
    /**
     * Graph Settings Template
     *
     */
    'settings/template/project': 'Project',
    'settings/template/tunnel': 'Tunnel',
    'settings/template/pegNo': 'Peg',
    'settings/template/screenNo': 'Screen',
    'settings/template/holeNo': 'Hole',
    'settings/template/pumpNr': 'Pump',
    'settings/template/project/placeholder': 'The Northern Tunnel Project',
    'settings/template/tunnel/placeholder': '10th Northbound',
    'settings/template/pegNo/placeholder': '119675',
    'settings/template/screenNo/placeholder': '2',
    'settings/template/holeNo/placeholder': '41',
    'settings/template/pumpNr/placeholder': '2',
    /**
     * Colors
     */

    'settings/color/flow': 'Flow',
    'settings/color/pressure': 'Pressure',
    'settings/color/volume': 'Volume',
    'settings/color/more': 'More color options',
    'settings/color/less': 'Less color options',
    'settings/color/hint': 'Click on the circles to open the color picker.',
    /**
     * Chart.js Graphs
     */
    graph: 'Graph',
    'graph/title': 'Hole ',
    'graph/title/hint': 'Hint:',
    'graph/title/hint/text': 'Use the  @ symbol to select a template',
    'graph/label/x1': 'Time [hh:mm]',
    'graph/label/y1': 'Pressure (bar) and flow (l/min)',
    'graph/label/y2': 'Volume (liter)',
    'graph/legend/flow': 'Flow',
    'graph/legend/pressure': 'Pressure',
    'graph/legend/volume': 'Volume',
    'graph/tooltip/x': 'Time',
    hero: 'hjälte',
    'hero/view-graphs': 'View Graphs',
    'hero/view-graphs/popover/title': 'Info',
    'hero/view-graphs/popover/content':
      'Use this tool to create rock grouting charts',
    'hero/and': 'and',
    'hero/estimate-aperture': 'Estimate Aperture',
    'hero/click': 'Click',
    'hero/here': 'Here',
    'hero/select-xml-file': 'To select an XML file',
    'hero/or': 'or',
    'hero/estimate-spread': 'Estimate Spread',
    'hero/estimate-spread/popover/title': 'Info',
    'hero/estimate-spread/popover/content':
      'Use this tool to create rock grouting charts',
    'hero/default-settings': 'Defualt Settings',
    'hero/custom-settings': 'Custom Settings',
    back: 'Go Back',
    error: 'error',
    'error/error': 'Oh Snap!',
    'error/message': 'Something went wrong.',
    'error/reason/file':
      "Rock Grouting Toolkit couldn't load your file. We apologize for the inconvenience causedPlease try with another file.",
    'error/contact': 'If you have any questions, please contact us at',
  },
};

export { values, Values };
