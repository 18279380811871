import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ViewChildren,
  QueryList,
} from '@angular/core';
declare const $: any;

// declare const HSStickyBlock: any;
import { readGroutFile } from 'src/assets/grout/grout.module.js';
import { BaseChartDirective } from 'ng2-charts';
import { ChartDataSets, ChartType, ChartOptions } from 'chart.js';
import * as moment from 'moment';
import * as HSStickyBlock from 'src/assets/_theme/vendor/hs-sticky-block/dist/hs-sticky-block.min.js';
import * as HSUnfold from 'src/assets/_theme/vendor/hs-unfold/dist/hs-unfold.min.js';
import * as HSGoTo from 'src/assets/_theme/vendor/hs-go-to/dist/hs-go-to.min.js';
import { QuillEditorComponent } from 'ngx-quill';
import {
  getFlowData,
  getPressureData,
  getVolumeData,
} from 'src/app/dev/playground/get-data.js';
import * as withinviewport from 'withinviewport';

// Multi axis: https://github.com/valor-software/ng2-charts/issues/266
@Component({
  selector: 'app-playground',
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.scss'],
})
export class PlaygroundComponent implements OnInit, AfterViewInit {
  @ViewChild(QuillEditorComponent, { static: true })
  editor: QuillEditorComponent;

  modules = {
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,

      // onSelect: (item, insertItem) => {
      //   // console.log(item.id);

      //   const editor = this.editor.quillEditor;

      //   insertItem(item);

      //   // necessary because quill-mention triggers changes as 'api' instead of 'user'
      //   editor.insertText(editor.getLength() - 1, '', 'user');
      // },
      // source: (searchTerm, renderList) => {
      //   const values = [
      //     { id: 1, value: 'Fredrik Sundqvist' },
      //     { id: 2, value: 'Patrik Sjölin' },
      //   ];

      //   if (searchTerm.length === 0) {
      //     renderList(values, searchTerm);
      //   } else {
      //     const matches = [];

      //     values.forEach((entry) => {
      //       if (
      //         entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
      //       ) {
      //         matches.push(entry);
      //       }
      //     });
      //     renderList(matches, searchTerm);
      //   }
      // },
    },
    toolbar: false,
  };
  chartDummyString = '';
  chartDummyValues = {
    project: 'Bypass Stockholm',
    tunnel: 'SN401',
    pegNo: '201',
    screenNo: '22',
    holeNo: '54',
    pumpNr: '4',
  };

  storageName = 'rock-grouting-toolkit';
  storage = {
    language: 'en',
    units: 'metric',
    color: 'red',
    scale: 'auto',
    chartTitle: '${holeNo}',
    quillRoot: '',
  };
  foo(editor) {
    const innerHTML = this.editor.quillEditor.root.innerHTML;
    const parser = new DOMParser();
    const doc = parser.parseFromString(innerHTML, 'text/html');
    const p = doc.getElementsByTagName('p')[0];
    const childNodes = p.childNodes;
    const children = p.children;

    let text = '';
    let k = 0;
    for (let index = 0; index < childNodes.length; index++) {
      const childNode = childNodes[index];

      if (childNode.nodeName === '#text') {
        text += childNode.nodeValue;
      } else {
        const dataId = children[k].getAttribute('data-id');
        if (dataId === null) continue;

        text += '${' + dataId + '}';
        k++;
      }
    }
    let dummyString = text;

    Object.keys(this.chartDummyValues).forEach((key) => {
      dummyString = dummyString.replace(
        '${' + key + '}',
        this.chartDummyValues[key]
      );
    });
    this.chartDummyString = dummyString;
    this.settingsChart.options.title.text = dummyString;
    this.settingsChart.options = { ...this.settingsChart.options };

    const key = 'chartTitle';
    const value = text;
    this.storage[key] = value;

    this.storage['quillRoot'] = this.editor.quillEditor.getContents(); //this.editor.quillEditor.root.innerHTML;

    window.localStorage[this.storageName] = JSON.stringify(this.storage);
  }
  bar(editor) {
    editor.setContents(this.storage['quillRoot']);
  }
  initStorage() {
    if (window.localStorage[this.storageName] === undefined) {
      window.localStorage[this.storageName] = JSON.stringify(this.storage);
    } else {
      var data = JSON.parse(window.localStorage[this.storageName]);
      for (var key in data) {
        this.storage[key] = data[key];
      }
    }
  }

  public locale = {
    sv: {
      settings: 'Inställningar',
      language: 'Språk',
      units: 'Måttenheter',
      color: 'Färg',
      scale: 'Skala',
      graph: 'Graf',
      'graph/title': 'Graftitel',
      'graph/title/hint': 'Tips:',
      'graph/title/hint/text': 'Använd @-symbolen för att välja en mall',
    },
    en: {
      settings: 'Settings',
      language: 'Language',
      units: 'Units',
      color: 'Color',
      scale: 'Scale',
      graph: 'Graph',
      'graph/title': 'Graph Title',
      'graph/title/hint': 'Hint:',
      'graph/title/hint/text': 'Use the  @ symbol to select a template',
    },
  };
  getLocale(key) {
    return this.locale[this.storage.language][key];
  }
  public appSettings = [
    {
      id: 'language',
      name: this.getLocale('language'),
      type: 'select',
      options: {
        sv: { sv: 'Svenska', en: 'Engelska' },
        en: { sv: 'Swedish', en: 'English' },
      },
    },
    {
      id: 'units',
      name: this.getLocale('units'),
      options: {
        sv: { metric: 'Metersystemet' },
        en: { metric: 'Metric System' },
      },
    },
    {
      id: 'color',
      name: this.getLocale('color'),
      type: 'select',
      options: {
        sv: { red: 'Röd', green: 'Grön' },
        en: { red: 'Red', green: 'Green' },
      },
      selected: 'metric',
    },
    {
      id: 'scale',
      name: this.getLocale('scale'),
      type: 'select',
      options: {
        sv: { manual: 'Manual skala', auto: 'Automatisk skala' },
        en: { manual: 'Manual scale', auto: 'Automatic scale' },
      },
      selected: 'metric',
    },
  ];

  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;

  public fileLoaded: boolean = false;
  public operatingLogs: any;
  public operatingLog: any = {
    number: 0,
    info: {
      totalFlow: 0,
      totalPressure: 0,
      totalVolume: 0,
    },
  };
  private chartOptions: ChartOptions = {
    // responsive: true,
    // maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          ticks: {
            callback: function (label, index, labels) {
              return moment(label).format('HH:mm:ss');
            },
          },
          type: 'linear',
          position: 'bottom',
          scaleLabel: {
            display: true,
            labelString: 'Time',
          },
        },
      ],
      yAxes: [
        {
          id: 'y1',
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: 'Flow & Pressure',
          },
        },
        {
          id: 'y2',
          position: 'right',
          scaleLabel: {
            display: true,
            labelString: 'Volume',
          },
        },
      ],
    },
  };
  public chartType: ChartType = 'scatter';
  private chartData: ChartDataSets = {
    showLine: true,
    fill: false,
    lineTension: 0,
    borderWidth: 1,
    pointRadius: 1,
  };
  private colors = [
    'rgba(255, 99, 132, 1)',
    'rgba(0, 0, 0, 1)',
    'rgba(0, 0, 165, 1)',
  ];
  private requestScroll: boolean;

  public settingsChart = {
    datasets: [
      Object.assign(
        {
          label: 'Flow',
          yAxisID: 'y1',
          data: [
            { x: '1612343666000', y: '15.7' },
            { x: '1612343682000', y: '36.9' },
            { x: '1612343698000', y: '37.9' },
            { x: '1612343714000', y: '38' },
            { x: '1612343730000', y: '39.2' },
            { x: '1612343826000', y: '2.9' },
            { x: '1612343842000', y: '18.4' },
            { x: '1612343858000', y: '11.1' },
            { x: '1612343878000', y: '7.8' },
            { x: '1612343902000', y: '13.2' },
            { x: '1612343918000', y: '15.3' },
            { x: '1612343934000', y: '12.6' },
            { x: '1612343950000', y: '8.5' },
            { x: '1612343970000', y: '9.6' },
            { x: '1612343986000', y: '9.8' },
            { x: '1612344002000', y: '7' },
            { x: '1612344018000', y: '5.4' },
            { x: '1612344034000', y: '6.1' },
            { x: '1612344050000', y: '5.9' },
            { x: '1612344066000', y: '5.8' },
            { x: '1612344082000', y: '2.6' },
            { x: '1612344098000', y: '4.3' },
            { x: '1612344114000', y: '4.3' },
            { x: '1612344130000', y: '3.9' },
            { x: '1612344146000', y: '3.7' },
            { x: '1612344162000', y: '3.5' },
            { x: '1612344178000', y: '3.7' },
            { x: '1612344194000', y: '3.2' },
            { x: '1612344210000', y: '3.6' },
            { x: '1612344226000', y: '4.6' },
            { x: '1612344242000', y: '3.6' },
            { x: '1612344258000', y: '4.5' },
            { x: '1612344274000', y: '3.3' },
            { x: '1612344290000', y: '4.8' },
            { x: '1612344306000', y: '3.3' },
            { x: '1612344322000', y: '3.6' },
            { x: '1612344338000', y: '3.1' },
            { x: '1612344354000', y: '2.8' },
            { x: '1612344370000', y: '2.9' },
            { x: '1612344386000', y: '2.2' },
            { x: '1612344402000', y: '3.2' },
            { x: '1612344418000', y: '3.2' },
            { x: '1612344434000', y: '3.3' },
            { x: '1612344450000', y: '3.2' },
            { x: '1612344466000', y: '3.3' },
          ],
          backgroundColor: this.colors[0],
          borderColor: this.colors[0],
          pointBackgroundColor: this.colors[0],
          pointBorderColor: this.colors[0],
        },
        this.chartData
      ),
      Object.assign(
        {
          label: 'Pressure',
          yAxisID: 'y1',
          data: [
            { x: '1612343666000', y: '2.2' },
            { x: '1612343682000', y: '0.9' },
            { x: '1612343698000', y: '0.7' },
            { x: '1612343714000', y: '1.8' },
            { x: '1612343730000', y: '2.2' },
            { x: '1612343826000', y: '0.7' },
            { x: '1612343842000', y: '3.5' },
            { x: '1612343858000', y: '15.7' },
            { x: '1612343878000', y: '42.7' },
            { x: '1612343902000', y: '42' },
            { x: '1612343918000', y: '41.8' },
            { x: '1612343934000', y: '40.7' },
            { x: '1612343950000', y: '41' },
            { x: '1612343970000', y: '42.7' },
            { x: '1612343986000', y: '42.9' },
            { x: '1612344002000', y: '43.2' },
            { x: '1612344018000', y: '43.2' },
            { x: '1612344034000', y: '43' },
            { x: '1612344050000', y: '43.1' },
            { x: '1612344066000', y: '43.3' },
            { x: '1612344082000', y: '41.2' },
            { x: '1612344098000', y: '43.4' },
            { x: '1612344114000', y: '43.3' },
            { x: '1612344130000', y: '43.3' },
            { x: '1612344146000', y: '43.3' },
            { x: '1612344162000', y: '43' },
            { x: '1612344178000', y: '43.3' },
            { x: '1612344194000', y: '42.7' },
            { x: '1612344210000', y: '43.1' },
            { x: '1612344226000', y: '42.9' },
            { x: '1612344242000', y: '43.1' },
            { x: '1612344258000', y: '43.4' },
            { x: '1612344274000', y: '43.1' },
            { x: '1612344290000', y: '43.4' },
            { x: '1612344306000', y: '43.1' },
            { x: '1612344322000', y: '43.1' },
            { x: '1612344338000', y: '43.1' },
            { x: '1612344354000', y: '42.8' },
            { x: '1612344370000', y: '43' },
            { x: '1612344386000', y: '41.7' },
            { x: '1612344402000', y: '43.1' },
            { x: '1612344418000', y: '43.1' },
            { x: '1612344434000', y: '43.3' },
            { x: '1612344450000', y: '43.2' },
            { x: '1612344466000', y: '43.5' },
          ],
          backgroundColor: this.colors[1],
          borderColor: this.colors[1],
          pointBackgroundColor: this.colors[1],
          pointBorderColor: this.colors[1],
        },
        this.chartData
      ),
      Object.assign(
        {
          label: 'Pressure',
          yAxisID: 'y2',
          data: [
            { x: '1612343666000', y: '5' },
            { x: '1612343682000', y: '16' },
            { x: '1612343698000', y: '27' },
            { x: '1612343714000', y: '37' },
            { x: '1612343730000', y: '48' },
            { x: '1612343826000', y: '59' },
            { x: '1612343842000', y: '67' },
            { x: '1612343858000', y: '73' },
            { x: '1612343878000', y: '75' },
            { x: '1612343902000', y: '79' },
            { x: '1612343918000', y: '83' },
            { x: '1612343934000', y: '87' },
            { x: '1612343950000', y: '89' },
            { x: '1612343970000', y: '93' },
            { x: '1612343986000', y: '95' },
            { x: '1612344002000', y: '97' },
            { x: '1612344018000', y: '99' },
            { x: '1612344034000', y: '100' },
            { x: '1612344050000', y: '102' },
            { x: '1612344066000', y: '103' },
            { x: '1612344082000', y: '105' },
            { x: '1612344098000', y: '106' },
            { x: '1612344114000', y: '107' },
            { x: '1612344130000', y: '108' },
            { x: '1612344146000', y: '109' },
            { x: '1612344162000', y: '110' },
            { x: '1612344178000', y: '111' },
            { x: '1612344194000', y: '112' },
            { x: '1612344210000', y: '113' },
            { x: '1612344226000', y: '114' },
            { x: '1612344242000', y: '115' },
            { x: '1612344258000', y: '116' },
            { x: '1612344274000', y: '117' },
            { x: '1612344290000', y: '118' },
            { x: '1612344306000', y: '119' },
            { x: '1612344322000', y: '120' },
            { x: '1612344338000', y: '121' },
            { x: '1612344354000', y: '122' },
            { x: '1612344370000', y: '123' },
            { x: '1612344386000', y: '124' },
            { x: '1612344402000', y: '125' },
            { x: '1612344418000', y: '125' },
            { x: '1612344434000', y: '126' },
            { x: '1612344450000', y: '127' },
            { x: '1612344466000', y: '128' },
          ],
          backgroundColor: this.colors[2],
          borderColor: this.colors[2],
          pointBackgroundColor: this.colors[2],
          pointBorderColor: this.colors[2],
        },
        this.chartData
      ),
    ],
    options: Object.assign(
      {
        title: {
          display: true,
          text: '',
        },
      },
      this.chartOptions
    ),
  };
  constructor() {}

  /**
   * Init HS Sticky Block & Range Slider
   */
  ngOnInit(): void {
    this.initStorage();
    this.requestScroll = true;

    this.toggleLoader(0);
  }

  ngAfterViewInit(): void {
    const _this = this;

    _this.editor.modules.mention.source = (searchTerm, renderList) => {
      const values = [
        { id: 'project', value: 'Project' },
        { id: 'tunnel', value: 'Tunnel' },
        { id: 'pegNo', value: 'Peg Number' },
        { id: 'screenNo', value: 'Screen Number' },
        { id: 'holeNo', value: 'Hole Number' },
        { id: 'pumpNr', value: 'Pump' },
      ];

      if (searchTerm.length === 0) {
        renderList(values, searchTerm);
      } else {
        const matches = [];

        values.forEach((entry) => {
          if (
            entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
          ) {
            matches.push(entry);
          }
        });
        renderList(matches, searchTerm);
      }
    };

    new HSUnfold($('#sidebar-open-button')).init();
    new HSUnfold($('#sidebar-close-button')).init();

    $('.js-go-to').each(function () {
      var goTo = new HSGoTo($(this)).init();
    });

    $('.js-custom-select').each(function () {
      const select2 = $.HSCore.components.HSSelect2.init($(this));
      select2.on('select2:select', (e) => {
        const key = e.params.data.element.parentElement.id;
        const value = e.params.data.id;
        _this.storage[key] = value;
        window.localStorage[_this.storageName] = JSON.stringify(_this.storage);
      });
    });
  }

  toggleLoader(milliseconds): void {
    setTimeout(
      (milliseconds) => $('#jsPreloader').fadeOut(milliseconds),
      milliseconds
    );
  }
  /**
   * On File Change
   */
  onChange(event: Event) {
    const cb = (groutFile) => {
      let { operatingLogs } = groutFile;

      Object.keys(operatingLogs).forEach((key: any) => {
        const { number, logs } = operatingLogs[key];

        operatingLogs[key].chartOptions = Object.assign(
          {
            title: {
              display: true,
              text: `Grout Chart ${number}`,
            },
          },
          this.chartOptions
        );

        const getTotal = (data) => {
          let sum = 0;
          data.forEach((dataPair) => {
            sum += +dataPair.y;
          });

          return (~~sum).toLocaleString('sv');
        };

        const flowData = getFlowData(logs);
        const pressureData = getPressureData(logs);
        const volumeData = getVolumeData(logs);

        console.log(JSON.stringify(volumeData));

        const totalFlow = getTotal(flowData);
        const totalPressure = getTotal(pressureData);
        const totalVolume = getTotal(volumeData);
        operatingLogs[key].info = {
          totalFlow,
          totalPressure,
          totalVolume,
        };
        operatingLogs[key].data = [
          Object.assign(
            {
              label: 'Flow',
              yAxisID: 'y1',
              data: flowData,
              backgroundColor: this.colors[0],
              borderColor: this.colors[0],
              pointBackgroundColor: this.colors[0],
              pointBorderColor: this.colors[0],
            },
            this.chartData
          ),
          Object.assign(
            {
              label: 'Pressure',
              yAxisID: 'y1',
              data: pressureData,
              backgroundColor: this.colors[1],
              borderColor: this.colors[1],
              pointBackgroundColor: this.colors[1],
              pointBorderColor: this.colors[1],
            },
            this.chartData
          ),
          Object.assign(
            {
              label: 'Pressure',
              yAxisID: 'y2',
              data: volumeData,
              backgroundColor: this.colors[2],
              borderColor: this.colors[2],
              pointBackgroundColor: this.colors[2],
              pointBorderColor: this.colors[2],
            },
            this.chartData
          ),
        ];
      });
      this.operatingLogs = Object.values(operatingLogs);

      this.fileLoaded = true;
      this.initApp();
    };
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      const groutFile = readGroutFile(event, cb);
    }
  }

  getAllCharts() {
    console.log(this.charts);
  }
  setOperatingLog(id) {
    this.operatingLog = this.operatingLogs[id];
    console.log(this.operatingLog);
  }

  initApp() {
    /**
     * Init View
     */
    $('.js-sticky-block').each(function () {
      var stickyBlock = new HSStickyBlock($(this)).init();
    });
    $('.js-ion-range-slider').each(function () {
      $.HSCore.components.HSIonRangeSlider.init($(this), {
        onChange: function (data) {
          let { from: id } = data;
          _this.requestScroll = false;
          _this.setOperatingLog(data.from);
          if (data.from === 0) {
            id = `canvas-${id}`;
          } else {
            id = `canvas-${+id - 1}`;
          }

          const element = document.getElementById(id);
          if (element) element.scrollIntoView();
        },
        onFinish: function (data) {
          _this.requestScroll = true;
        },
      });
    });
    /**
     * Add Scroll
     */
    const _this = this;
    $(window).bind('scroll', () => {
      if (_this.requestScroll === false) return;
      $('.graph')
        .withinviewport({ sides: 'right' })
        .each(function () {
          const val = $(this).children()[0].number;
          const rangeSlider = $('.js-ion-range-slider').data('ionRangeSlider');
          _this.setOperatingLog(val);
          rangeSlider.update({
            from: val,
          });
        });
    });
  }
}
