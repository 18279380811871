import { OperatingLog } from './grout-js';
const getFlowData = (logs: [OperatingLog]) => getData(logs, 'flow');
const getPressureData = (logs: [OperatingLog]) => getData(logs, 'pressure');
const getVolumeData = (logs: [OperatingLog]) => getData(logs, 'volume');

const getData = (logs: [OperatingLog], dataType: string) => {
  const data: { [key: string]: string }[] = [];
  logs.forEach((operatingLog) => {
    const x: string = operatingLog.logTime.unixTimestamp;
    const y: string = operatingLog[dataType].value;
    data.push({ x, y });
  });
  return data;
};

export { getFlowData, getPressureData, getVolumeData };
