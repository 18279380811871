<!-- Simple Settings -->
<div>
  <div class="row">
    <!-- Settings -->
    <div class="pr-4 col-4">
      <!-- Card -->
      <div class="card">
        <div class="card-header bg-primary">
          <h5 class="card-title text-white">
            {{ s.getLocale("settings/graph/simple") }}
          </h5>
        </div>

        <!-- Body -->
        <div class="card-body">
          <p class="card-text">
            {{ s.getLocale("settings/graph/simple/tips") }}
          </p>
          <!-- Form -->

          <!-- Title -->
          <div class="row justify-content-between align-items-end">
            <div class="col-6">
              <h2 class="h5 mb-0">{{ s.getLocale("settings/graph/show") }}</h2>
            </div>
            <div class="col-6 text-right">
              <a
                (click)="toggleAll($event)"
                id="toggleAll1"
                class="js-toggle-state link-underline"
                href="javascript:;"
                [attr.data-hs-toggle-state-options]="targetSelector"
              >
                <span class="link-underline-toggle-default" id="toggle-all">
                  {{ s.getLocale("settings/graph/toggle-all") }}</span
                >
                <span class="link-underline-toggle-toggled" id="untoggle-all">
                  {{ s.getLocale("settings/graph/untoggle-all") }}</span
                >
              </a>
            </div>
          </div>
          <!-- End Title -->
          <!-- Divider  -->
          <hr class="mt-2 mb-4" />
          <!-- End Divider  -->
          <div *ngFor="let settingsGroup of settingsGroups">
            <!-- Group Name -->
            <div class="mb-3">
              <small class="card-subtitle"
                >{{ s.getLocale(settingsGroup.id) }}:</small
              >
            </div>

            <!-- End Group Name -->
            <div *ngFor="let setting of settingsGroup.options">
              <!-- Option  -->
              <div [ngSwitch]="setting.type">
                <!-- CASE: Checkbox -->
                <div *ngSwitchCase="'checkbox'">
                  <!--  Checkbox Switch -->

                  <div class="custom-control custom-switch">
                    <input
                      (change)="inputRadioChange($event)"
                      type="checkbox"
                      class="custom-control-input"
                      name="{{ setting.id }}"
                      id="{{ setting.id }}"
                      value="{{ setting.id }}"
                      [attr.checked]="
                        s.getOption(setting.id) === 'yes' ? '' : null
                      "
                    />
                    <label class="custom-control-label" for="{{ setting.id }}">
                      <span class="d-block font-weight-bold">{{
                        setting.locales
                      }}</span>

                      <!-- <small class="d-block text-muted"
                  >A weekly email featuring shots from designers you follow</small
                > -->
                    </label>
                  </div>
                  <!-- End Checkbox Switch -->
                  <hr class="my-3" />

                  <!-- CASE: Radio -->
                  <div *ngSwitchCase="'radio'">
                    <!-- Form Group -->
                    <div class="form-group">
                      <!--  Input Group -->
                      <div class="input-group input-group-md-down-break">
                        <!-- Custom Radio -->
                        <div
                          *ngFor="
                            let option of setting.options[s.getLanguage()]
                              | keyvalue
                          "
                          class="form-control"
                        >
                          <div class="custom-control custom-radio">
                            <input
                              (change)="inputRadioChange($event)"
                              type="radio"
                              class="custom-control-input"
                              name="{{ setting.id }}"
                              id="{{ setting.id }}{{ option.key }}"
                              value="{{ option.key }}"
                              [attr.checked]="
                                s.getOption(setting.id) === option.key
                                  ? ''
                                  : null
                              "
                            />
                            <label
                              class="custom-control-label"
                              for="{{ setting.id }}{{ option.key }}"
                              >{{ option.value }}</label
                            >
                          </div>
                        </div>
                        <!-- End Custom Radio -->
                      </div>
                      <!-- End Input Group -->
                    </div>
                    <!-- End Form Group -->
                  </div>
                  <!-- CASE: Switch -->
                  <div *ngSwitchCase="'select'">
                    <!-- Select2 -->
                    <select
                      id="{{ setting.id }}"
                      class="js-custom-select custom-select"
                      size="1"
                      style="opacity: 0"
                      data-hs-select2-options='{
               "minimumResultsForSearch": "Infinity"
             }'
                    >
                      <!-- Select2 Option -->
                      <option
                        *ngFor="
                          let option of setting.options[s.getLanguage()]
                            | keyvalue
                        "
                        [attr.selected]="
                          s.getOption(setting.id) === option.key ? '' : null
                        "
                      >
                        {{ option.value }}
                      </option>

                      <!-- End Select2 Option -->
                    </select>
                    <!-- End Select2 -->
                  </div>
                </div>
                <!-- End Option  -->
              </div>
              <!-- End Title -->
            </div>
          </div>
        </div>
        <!-- End Settings -->
      </div>
      <!-- End Card -->
    </div>

    <div class="pl-4 col-8">
      <!-- Advanced Settings -->
      <div>
        <div>
          <!-- Card -->
          <div class="card">
            <div class="card-header bg-primary">
              <h5 class="card-title text-white">
                {{ s.getLocale("settings/graph/advanced") }}
              </h5>
            </div>

            <!-- Body -->
            <div class="card-body">
              <p class="card-text">
                {{ s.getLocale("settings/graph/advanced/text") }}
              </p>
              <!-- Form -->
              <form>
                <!-- Form Group -->
                <div class="row form-group">
                  <label
                    for="currentPasswordLabel"
                    class="col-sm-3 col-form-label input-label"
                    >Title</label
                  >

                  <div class="col-sm-9">
                    <!-- Quill Editor -->
                    <div>
                      <quill-editor
                        [modules]="modules"
                        (onEditorChanged)="onEditorChanged($event)"
                        (onEditorCreated)="onEditorCreated($event)"
                      >
                      </quill-editor>
                    </div>
                    <!-- End Quill Editor -->
                    <small class="form-text">{{
                      s.getLocale("settings/graph/advanced/tips")
                    }}</small>
                  </div>
                </div>
                <!-- End Form Group -->
                <!-- Form Group -->
                <div class="row form-group">
                  <label
                    for="currentPasswordLabel"
                    class="col-sm-3 col-form-label input-label walla"
                  >
                    {{ s.getLocale("settings/colors") }}</label
                  >

                  <div class="col-sm-9">
                    <!-- Colors  -->
                    <div class="row">
                      <table class="table table-borderless">
                        <thead>
                          <tr>
                            <!-- Color Names -->
                            <th
                              *ngFor="let colorSetting of colorSettings"
                              scope="col"
                            >
                              {{ s.getLocale("settings/" + colorSetting.id) }}
                            </th>
                            <!-- End Color Names -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <!-- Color Editor -->
                            <td *ngFor="let colorSetting of colorSettings">
                              <!-- Avatar -->
                              <div
                                (click)="handleClickEvent(colorSetting.id)"
                                [style.background-color]="
                                  getColor(colorSetting.id)
                                "
                                class="avatar avatar-xs avatar-circle transition-3d-hover"
                              ></div>
                              <!-- End Avatar -->
                              <!-- <button
                                (click)="handleClickEvent(colorSetting.id)"
                                [style.background-color]="
                                  getColor(colorSetting.id)
                                "
                                type="button"
                                class="btn transition-3d-hover"
                              >
                                <span class="text-white">
                                  <i class="far fa-edit mr-1"></i>Edit</span
                                >
                              </button> -->
                              <!-- Color Picker -->
                              <!-- <div
                                class="mt-2"
                                style="position: absolute; z-index: 1"
                              > -->
                              <div class="mt-2">
                                <div
                                  *ngIf="colorPickers[colorSetting.id].visible"
                                  #colorPicker
                                >
                                  <div
                                    *ngIf="
                                      useSimpleColorPicker;
                                      then simpleColorPicker;
                                      else advancedColorPicker
                                    "
                                  ></div>
                                  <!-- Simple Color Picker -->
                                  <ng-template #simpleColorPicker>
                                    <color-twitter
                                      (onChangeComplete)="
                                        handleChange($event, colorSetting.id)
                                      "
                                    ></color-twitter>
                                    <div class="row pl-3">
                                      <a
                                        (click)="toggleSimpleColorPicker(false)"
                                        class="form-link btn btn-xs btn-no-focus btn-ghost-primary"
                                        id="toggleColorPicker"
                                      >
                                        <i class="fas fa-plus mr-1"></i>
                                        {{ s.getLocale("settings/color/more") }}
                                      </a>
                                    </div>
                                  </ng-template>
                                  <!-- End Simple Color Picker -->
                                  <!-- Advanced Color Picker -->
                                  <ng-template #advancedColorPicker>
                                    <color-sketch
                                      (onChange)="
                                        handleChange($event, colorSetting.id)
                                      "
                                    ></color-sketch>
                                    <div class="row">
                                      <a
                                        (click)="toggleSimpleColorPicker(true)"
                                        class="form-link btn btn-xs btn-no-focus btn-ghost-primary"
                                        id="toggleColorPicker"
                                      >
                                        <i class="fas fa-minu mr-1"></i>
                                        {{ s.getLocale("settings/color/less") }}
                                      </a>
                                    </div>
                                  </ng-template>
                                  <!-- End Advanced Color Picker -->
                                </div>
                              </div>

                              <!-- <div>
                                <div
                                  (click)="foo()"
                                  [style.background-color]="flowColor.hex"
                                  class="avatar avatar-xs avatar-circle mr-3"
                                ></div>
                                <div class="mt-2 color-picker">
                                  <div *ngIf="showColorPicker" #colorPicker>
                                    <div
                                      *ngIf="
                                        useSimpleColorPicker;
                                        then simpleColorPicker;
                                        else advancedColorPicker
                                      "
                                    ></div>
                                    <ng-template #simpleColorPicker>
                                      <color-twitter
                                        (onChangeComplete)="
                                          handleChange($event)
                                        "
                                      ></color-twitter>
                                      <div class="row">
                                        <a
                                          (click)="
                                            toggleSimpleColorPicker(false)
                                          "
                                          class="form-link btn btn-xs btn-no-focus btn-ghost-primary"
                                          id="toggleColorPicker"
                                        >
                                          <i class="fas fa-plus mr-1"></i>
                                          More color options
                                        </a>
                                      </div>
                                    </ng-template>
                                    <ng-template #advancedColorPicker>
                                      <color-sketch
                                        (onChange)="handleChange($event)"
                                      ></color-sketch>
                                      <div class="row">
                                        <a
                                          (click)="
                                            toggleSimpleColorPicker(true)
                                          "
                                          class="form-link btn btn-xs btn-no-focus btn-ghost-primary"
                                          id="toggleColorPicker"
                                        >
                                          <i class="fas fa-minu mr-1"></i>
                                          Less color options
                                        </a>
                                      </div>
                                    </ng-template>
                                  </div>
                                </div>
                              </div> -->
                            </td>

                            <!-- Color Editor -->
                          </tr>
                        </tbody>
                      </table>

                      <!-- <color-twitter [triangle]="'hide'"></color-twitter> -->
                    </div>
                    <!-- Color -->
                    <small class="form-text">{{
                      s.getLocale("settings/color/hint")
                    }}</small>
                    <!-- <div class="row">
                      <a
                        class="form-link btn btn-xs btn-no-focus btn-ghost-primary"
                      >
                        <i class="fas fa-plus mr-1"></i>
                        More color options
                      </a>
                    </div> -->
                  </div>
                </div>
                <!-- End Form Group -->
              </form>
              <!-- End Form -->
            </div>
            <!-- End Body -->
          </div>
          <!-- End Card -->
        </div>
      </div>
      <!-- End Advanced Settings -->
      <!-- Divider  -->
      <div class="my-4"></div>
      <!-- End Divider  -->
      <!-- Sticky Block Start Point -->
      <div id="stickyBlockStartPoint"></div>
      <!-- End Sticky Block Start Point -->
      <!-- Settings Graph -->
      <div
        class="js-sticky-block"
        data-hs-sticky-block-options='{
       "parentSelector": "#stickyBlockStartPoint",
       "startPoint": "#stickyBlockStartPoint",
       "endPoint": "#stickyBlockEndPoint",
       "stickyOffsetTop": 50,
       "stickyOffsetBottom": 0
     }'
      >
        <!-- Title -->
        <div class="row justify-content-between align-items-end">
          <div class="col-6">
            <h2 class="h5 mb-0">{{ s.getLocale("settings/example") }}</h2>
          </div>
          <div class="col-6 text-right">
            <a class="js-toggle-state link-underline" href="javascript:;">
              &#10240;
            </a>
          </div>
        </div>
        <!-- End Title -->
        <!-- Divider  -->
        <hr class="mt-2 mb-4" />
        <!-- End Divider  -->
        <div class="card card-frame">
          <div class="card-body">
            <div class="graphs">
              <div class="graph" *ngFor="let graph of graphs">
                <div
                  id="{{ 'canvas-' + graph.number }}"
                  [attr.data-number]="graph.number"
                >
                  <canvas
                    baseChart
                    #myChart="base-chart"
                    [id]="graph.number"
                    [datasets]="graph.data"
                    [options]="graph.options"
                    chartType="scatter"
                  >
                  </canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Settings Graph -->
    </div>
  </div>
  <!-- Sticky Block End Point -->
  <div id="stickyBlockEndPoint"></div>
  <!-- End Sticky Block End Point -->
</div>
<div class="space-1"></div>
