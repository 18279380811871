import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './dev/home/home.component';
import { SettingsComponent } from './components/settings/settings.component';
import { GraphsComponent } from './components/graphs/graphs.component';
import { PlaygroundComponent } from './dev/playground/playground.component';
import { SettingsGraphComponent } from './components/settings/settings-graph/settings-graph.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'graphs', component: GraphsComponent },
  { path: 'walla', component: PlaygroundComponent },
  { path: 'foo', component: SettingsGraphComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
