import { ChartDataSets as DataSets } from 'chart.js';
/**
 * Chart.js Data Sets
 * https://www.chartjs.org/docs/latest/getting-started/?h=dataset
 */

/**** EXAMPLE ****
 *    data: {
 *      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
 *      datasets: [{
 *        label: 'My First dataset',
 *        backgroundColor: 'rgb(255, 99, 132)',
 *        borderColor: 'rgb(255, 99, 132)',
 *        data: [0, 10, 5, 2, 20, 30, 45]
 *      }]
 *    },
 **** EXAMPLE ****/
const dataSets: DataSets = {
  showLine: true,
  fill: false,
  lineTension: 0,
  borderWidth: 1,
  pointRadius: 1,
};

export { dataSets };
