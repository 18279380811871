import * as moment from 'moment';
/**
 * Shared Chart.js Settings
 */
import { dataSets } from 'src/graphs/chart-js/data/data-sets';
import { chartOptions } from 'src/graphs/chart-js/options/options';
/**
 * Grout.js Helper Functions
 */
import {
  getFlowData,
  getPressureData,
  getVolumeData,
} from 'src/grout-js/get-data';
/**
 * Interfaces
 */
import {
  GroutingLog,
  Hole,
  OperatingLog,
  OperatingLogAttributes,
  OperatingLogs,
} from 'src/grout-js/grout-js';

import {
  Chart,
  ChartOptions,
  ChartTooltipOptions,
  ChartLegendOptions,
  ChartScales,
  ChartTitleOptions,
} from 'chart.js';
/**
 * The Graphs class holds multiple graphs.
 * Each graph is derived from the same settings.
 */
class Graphs {
  /**
   * GroutFile
   */
  private metadata: any;
  private operatingLogs: OperatingLogs;
  /**
   * Localization
   */
  private localesTitle: string;
  private localesX1: string;
  private localesY1: string;
  private localesY2: string;
  private localesLegendFlow: string;
  private localesLegendPressure: string;
  private localesLegendVolume: string;
  private localesTooltipX: string;
  private graphs: any = [];
  private s: any;
  /**
   * Settings
   */
  private settingsShowFlow: boolean;
  private settingsShowPressure: boolean;
  private settingsShowVolume: boolean;
  private settingsShowChartTitle: boolean;
  private settingsShowChartLegend: boolean;
  private settingsShowX1Label: boolean;
  private settingsShowY1Label: boolean;
  private settingsShowY2Label: boolean;
  private settingsShowX1GridLines: boolean;
  private settingsShowY1GridLines: boolean;
  private settingsShowY2GridLines: boolean;
  private settingsFlowColor: string;
  private settingsPressureColor: string;
  private settingsVolumeColor: string;

  /**
   * Set locales and settings
   */
  constructor(groutFile, settings) {
    const { metadata, operatingLogs } = groutFile;
    /**
     * Set Settings Service
     */
    this.s = settings;
    /**
     * Set GroutFile
     */
    this.metadata = metadata;
    this.operatingLogs = operatingLogs;
    /**
     * Set Locales
     */
    this.localesTitle = this.s.getLocale('graph/title');
    this.localesX1 = this.s.getLocale('graph/label/x1');
    this.localesY1 = this.s.getLocale('graph/label/y1');
    this.localesY2 = this.s.getLocale('graph/label/y2');
    this.localesLegendFlow = this.s.getLocale('graph/legend/flow');
    this.localesLegendPressure = this.s.getLocale('graph/legend/pressure');
    this.localesLegendVolume = this.s.getLocale('graph/legend/volume');
    this.localesTooltipX = this.s.getLocale('graph/tooltip/x');
    /**
     * Set Settings
     */
    this.settingsShowFlow =
      this.s.getOption('graph/showFlow') === 'yes' ? true : false;
    this.settingsShowPressure =
      this.s.getOption('graph/showPressure') === 'yes' ? true : false;
    this.settingsShowVolume =
      this.s.getOption('graph/showVolume') === 'yes' ? true : false;
    this.settingsShowChartTitle =
      this.s.getOption('graph/showChartTitle') === 'yes' ? true : false;
    this.settingsShowChartLegend =
      this.s.getOption('graph/showChartLegend') === 'yes' ? true : false;
    this.settingsShowX1Label =
      this.s.getOption('graph/showX1Label') === 'yes' ? true : false;
    this.settingsShowY1Label =
      this.s.getOption('graph/showY1Label') === 'yes' ? true : false;
    this.settingsShowY2Label =
      this.s.getOption('graph/showY2Label') === 'yes' ? true : false;
    this.settingsShowX1GridLines =
      this.s.getOption('graph/showX1GridLines') === 'yes' ? true : false;
    this.settingsShowY1GridLines =
      this.s.getOption('graph/showY1GridLines') === 'yes' ? true : false;
    this.settingsShowY2GridLines =
      this.s.getOption('graph/showY2GridLines') === 'yes' ? true : false;
    this.settingsFlowColor = this.s.getOption('color/flow');
    this.settingsPressureColor = this.s.getOption('color/pressure');
    this.settingsVolumeColor = this.s.getOption('color/volume');
  }
  /**
   * Create N graphs
   */
  create = () => {
    Object.values(this.operatingLogs).forEach((operatingLog) => {
      this.graphs.push(this.createGraph(operatingLog));
    });
    return this.graphs;
  };
  /**
   * Create a single graph
   */
  createGraph = (operatingLog): any => {
    const { number, logs } = operatingLog;
    /**
     * Get Options
     */
    const options = this.extendChartOptions(number);
    /**
     * Get Raw Data from XML file
     */
    const {
      rawFlowData,
      rawPressureData,
      rawVolumeData,
    } = this.getRawChartData(logs);
    /**
     * Convert Raw Data to Chart.js data
     */
    const flowData = this.extendFlowData(rawFlowData);
    const pressureData = this.extendPressureData(rawPressureData);
    const volumeData = this.extendVolumeData(rawVolumeData);
    /**
     * Push data series
     */
    const data = [flowData, pressureData, volumeData];
    return { data, options, number };
  };
  /**
   * Extend Chart.js Options
   *
   * Some settings are shared between each graph, see:
   * "src/graphs/chart-js/options/options.ts"
   *
   * However, some are specific to each graph
   */
  extendChartOptions = (number: string) => {
    const _this = this;
    /**
     * GroutFile MetaData
     */
    const template = _this.s.getOption('template/chartTitle');
    const project = _this.metadata['Project'];
    const tunnel = _this.metadata['Tunnel'];
    const pegNo = _this.metadata['PegNo'];
    const screenNo = _this.metadata['ScreenNo'];
    const holeNo = number;
    /**
     * Convert tempalte litteral to text
     */
    const chartTitle: string = eval('`' + template + '`');
    /**
     * Tooltips Options
     */
    const tooltips: ChartTooltipOptions = {
      ...chartOptions.tooltips,

      callbacks: {
        label: function (tooltipItem, data) {
          const yAxisLabel = data.datasets[tooltipItem.datasetIndex].label;
          const xAxisLabel = _this.localesTooltipX;

          const xLabel: string = moment(+tooltipItem.xLabel).format('HH:mm:ss');
          const yLabel: string = <string>tooltipItem.yLabel;
          const label: string = `${yAxisLabel}: ${yLabel}. ${xAxisLabel}: ${xLabel}`;
          return label;
        },
      },
    };
    /**
     * Scale Options
     */
    const scales: ChartScales = {
      ...chartOptions.scales, // Extend old settings
      /**
       * Time,
       */
      xAxes: [
        // x1
        {
          ...chartOptions.scales.xAxes[0],
          gridLines: {
            ...chartOptions.scales.xAxes[0].gridLines,
            lineWidth: this.settingsShowX1GridLines ? 1 : 0,
          },
          scaleLabel: {
            ...chartOptions.scales.xAxes[0].scaleLabel,
            display: this.settingsShowX1Label,
            labelString: this.localesX1,
          },
        },
      ],
      /**
       * (Pressure + Flow ) & Volume
       */
      yAxes: [
        // y1
        {
          ...chartOptions.scales.yAxes[0],
          display:
            (this.settingsShowFlow && this.settingsShowPressure) === true
              ? true
              : false,
          gridLines: {
            ...chartOptions.scales.xAxes[0].gridLines,
            lineWidth: this.settingsShowY1GridLines ? 1 : 0,
          },
          scaleLabel: {
            ...chartOptions.scales.yAxes[0].scaleLabel,
            display: this.settingsShowY1Label,
            labelString: this.localesY1,
          },
        },
        // y2
        {
          ...chartOptions.scales.yAxes[1],
          display: this.settingsShowVolume === true ? true : false,
          gridLines: {
            ...chartOptions.scales.yAxes[1].gridLines,
            lineWidth: this.settingsShowY1GridLines ? 1 : 0,
            // ALWAYS ON
            drawOnChartArea: this.settingsShowY1GridLines ? false : true,
          },
          scaleLabel: {
            ...chartOptions.scales.yAxes[1].scaleLabel,
            display: this.settingsShowY2Label,
            labelString: this.localesY2,
          },
        },
      ],
    };
    /**
     * Title Options
     */
    const title: ChartTitleOptions = {
      display: this.settingsShowChartTitle,
      text: chartTitle,
    };
    /**
     * Legend Options
     */
    const legend: ChartLegendOptions = {
      display: this.settingsShowChartLegend,
      onClick: (e, legendItem) => {
        alert('No click!');
        return;
        const index = legendItem.datasetIndex;
        if (index !== 2) {
          Chart.defaults.global.legend.onClick;
          return;
        }

        const graph = this.graphs[0];

        const status = graph.options.scales.yAxes[1].scaleLabel.display;
        const newStatus = status ? false : true;
        graph.options.scales.yAxes[1].scaleLabel.display = newStatus;
        graph.options.scales.yAxes[1].gridLines.display = newStatus;

        graph.options.scales.yAxes[1].ticks.display = newStatus;
        graph.data[2].hidden = status;
        //
        this.graphs[0] = { ...graph };
      },
      labels: {
        filter: function (item, chart) {
          const text = item.text;
          const legendSettings = {
            [_this.localesLegendFlow]: _this.settingsShowFlow,
            [_this.localesLegendPressure]: _this.settingsShowPressure,
            [_this.localesLegendVolume]: _this.settingsShowVolume,
          };
          const showItem = legendSettings[text];
          return showItem;
        },
      },
    };

    /**
     * New Options
     */
    const newChartOptions: ChartOptions = {
      tooltips: tooltips,
      scales: scales,
      title: title,
      legend: legend,
    };
    return Object.assign({}, chartOptions, newChartOptions);
  };
  /**
   * Get RAW data from parsed XML file
   */
  getRawChartData = (logs: [OperatingLog]) => {
    const rawFlowData = getFlowData(logs);
    const rawPressureData = getPressureData(logs);
    const rawVolumeData = getVolumeData(logs);
    return { rawFlowData, rawPressureData, rawVolumeData };
  };
  /**
   * Convert RAW data to Chart.js data
   */
  extendFlowData = (rawFlowData: { [key: string]: string }[]) => {
    const label: string = this.localesLegendFlow;
    const color: string = this.settingsFlowColor;
    const hidden: boolean = this.settingsShowFlow ? false : true;
    return this.extendChartDataSets(rawFlowData, label, color, 'y1', hidden);
  };
  /**
   * Convert RAW data to Chart.js data
   */
  extendPressureData = (pressureData: { [key: string]: string }[]) => {
    const label: string = this.localesLegendPressure;
    const color: string = this.settingsPressureColor;
    const hidden: boolean = this.settingsShowPressure ? false : true;
    return this.extendChartDataSets(pressureData, label, color, 'y1', hidden);
  };
  /**
   * Convert RAW data to Chart.js data
   */
  extendVolumeData = (volumeData: { [key: string]: string }[]) => {
    const label: string = this.localesLegendVolume;
    const color: string = this.settingsVolumeColor;
    const hidden: boolean = this.settingsShowVolume ? false : true;
    return this.extendChartDataSets(volumeData, label, color, 'y2', hidden);
  };
  /**
   * Convert RAW data to Chart.js data
   */
  extendChartDataSets = (
    data: { [key: string]: string }[],
    label: string,
    color: string,
    yAxisID: string,
    hidden: boolean
  ) => {
    return Object.assign(
      {
        label: label,
        yAxisID: yAxisID,
        data: data,
        backgroundColor: color,
        borderColor: color,
        pointBackgroundColor: color,
        pointBorderColor: color,
        hidden: hidden,
      },
      dataSets
    );
  };
}

const initGraphs = (groutFile: any, settings: any) =>
  new Graphs(groutFile, settings).create();

export { initGraphs };
