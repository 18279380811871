import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { Location } from '@angular/common';
/**
 * jQuery
 */
declare const $: any;
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  constructor(
    private settingsService: SettingsService,
    private _location: Location
  ) {}
  public s = this.settingsService.settings;

  ngOnInit(): void {}
  ngAfterViewInit(): void {}

  back(): void {
    this._location.back();
  }
  reset(): void {
    const message = this.s.getLocale('settings/reset/confirm');
    if (confirm(message)) {
      localStorage.clear();
      location.reload();
    }
  }
}
