import { Hole, OperatingLog } from './grout-js';

const logs: Array<OperatingLog> = [
  {
    logTime: {
      value: '2021-02-03T09:14:26+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:14:26.000Z',
      unixTimestamp: '1612343666000',
    },
    pressure: { value: '2.2', key: 'Trykk' },
    flow: { value: '15.7', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '5', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:14:42+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:14:42.000Z',
      unixTimestamp: '1612343682000',
    },
    pressure: { value: '0.9', key: 'Trykk' },
    flow: { value: '36.9', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '16', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:14:58+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:14:58.000Z',
      unixTimestamp: '1612343698000',
    },
    pressure: { value: '0.7', key: 'Trykk' },
    flow: { value: '37.9', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '27', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:15:14+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:15:14.000Z',
      unixTimestamp: '1612343714000',
    },
    pressure: { value: '1.8', key: 'Trykk' },
    flow: { value: '38', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '37', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:15:30+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:15:30.000Z',
      unixTimestamp: '1612343730000',
    },
    pressure: { value: '2.2', key: 'Trykk' },
    flow: { value: '39.2', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '48', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:17:06+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:17:06.000Z',
      unixTimestamp: '1612343826000',
    },
    pressure: { value: '0.7', key: 'Trykk' },
    flow: { value: '2.9', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '59', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:17:22+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:17:22.000Z',
      unixTimestamp: '1612343842000',
    },
    pressure: { value: '3.5', key: 'Trykk' },
    flow: { value: '18.4', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '67', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:17:38+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:17:38.000Z',
      unixTimestamp: '1612343858000',
    },
    pressure: { value: '15.7', key: 'Trykk' },
    flow: { value: '11.1', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '73', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:17:58+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:17:58.000Z',
      unixTimestamp: '1612343878000',
    },
    pressure: { value: '42.7', key: 'Trykk' },
    flow: { value: '7.8', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '75', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:18:22+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:18:22.000Z',
      unixTimestamp: '1612343902000',
    },
    pressure: { value: '42', key: 'Trykk' },
    flow: { value: '13.2', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '79', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:18:38+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:18:38.000Z',
      unixTimestamp: '1612343918000',
    },
    pressure: { value: '41.8', key: 'Trykk' },
    flow: { value: '15.3', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '83', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:18:54+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:18:54.000Z',
      unixTimestamp: '1612343934000',
    },
    pressure: { value: '40.7', key: 'Trykk' },
    flow: { value: '12.6', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '87', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:19:10+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:19:10.000Z',
      unixTimestamp: '1612343950000',
    },
    pressure: { value: '41', key: 'Trykk' },
    flow: { value: '8.5', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '89', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:19:30+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:19:30.000Z',
      unixTimestamp: '1612343970000',
    },
    pressure: { value: '42.7', key: 'Trykk' },
    flow: { value: '9.6', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '93', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:19:46+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:19:46.000Z',
      unixTimestamp: '1612343986000',
    },
    pressure: { value: '42.9', key: 'Trykk' },
    flow: { value: '9.8', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '95', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:20:02+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:20:02.000Z',
      unixTimestamp: '1612344002000',
    },
    pressure: { value: '43.2', key: 'Trykk' },
    flow: { value: '7', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '97', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:20:18+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:20:18.000Z',
      unixTimestamp: '1612344018000',
    },
    pressure: { value: '43.2', key: 'Trykk' },
    flow: { value: '5.4', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '99', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:20:34+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:20:34.000Z',
      unixTimestamp: '1612344034000',
    },
    pressure: { value: '43', key: 'Trykk' },
    flow: { value: '6.1', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '100', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:20:50+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:20:50.000Z',
      unixTimestamp: '1612344050000',
    },
    pressure: { value: '43.1', key: 'Trykk' },
    flow: { value: '5.9', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '102', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:21:06+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:21:06.000Z',
      unixTimestamp: '1612344066000',
    },
    pressure: { value: '43.3', key: 'Trykk' },
    flow: { value: '5.8', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '103', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:21:22+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:21:22.000Z',
      unixTimestamp: '1612344082000',
    },
    pressure: { value: '41.2', key: 'Trykk' },
    flow: { value: '2.6', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '105', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:21:38+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:21:38.000Z',
      unixTimestamp: '1612344098000',
    },
    pressure: { value: '43.4', key: 'Trykk' },
    flow: { value: '4.3', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '106', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:21:54+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:21:54.000Z',
      unixTimestamp: '1612344114000',
    },
    pressure: { value: '43.3', key: 'Trykk' },
    flow: { value: '4.3', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '107', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:22:10+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:22:10.000Z',
      unixTimestamp: '1612344130000',
    },
    pressure: { value: '43.3', key: 'Trykk' },
    flow: { value: '3.9', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '108', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:22:26+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:22:26.000Z',
      unixTimestamp: '1612344146000',
    },
    pressure: { value: '43.3', key: 'Trykk' },
    flow: { value: '3.7', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '109', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:22:42+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:22:42.000Z',
      unixTimestamp: '1612344162000',
    },
    pressure: { value: '43', key: 'Trykk' },
    flow: { value: '3.5', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '110', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:22:58+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:22:58.000Z',
      unixTimestamp: '1612344178000',
    },
    pressure: { value: '43.3', key: 'Trykk' },
    flow: { value: '3.7', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '111', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:23:14+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:23:14.000Z',
      unixTimestamp: '1612344194000',
    },
    pressure: { value: '42.7', key: 'Trykk' },
    flow: { value: '3.2', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '112', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:23:30+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:23:30.000Z',
      unixTimestamp: '1612344210000',
    },
    pressure: { value: '43.1', key: 'Trykk' },
    flow: { value: '3.6', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '113', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:23:46+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:23:46.000Z',
      unixTimestamp: '1612344226000',
    },
    pressure: { value: '42.9', key: 'Trykk' },
    flow: { value: '4.6', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '114', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:24:02+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:24:02.000Z',
      unixTimestamp: '1612344242000',
    },
    pressure: { value: '43.1', key: 'Trykk' },
    flow: { value: '3.6', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '115', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:24:18+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:24:18.000Z',
      unixTimestamp: '1612344258000',
    },
    pressure: { value: '43.4', key: 'Trykk' },
    flow: { value: '4.5', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '116', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:24:34+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:24:34.000Z',
      unixTimestamp: '1612344274000',
    },
    pressure: { value: '43.1', key: 'Trykk' },
    flow: { value: '3.3', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '117', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:24:50+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:24:50.000Z',
      unixTimestamp: '1612344290000',
    },
    pressure: { value: '43.4', key: 'Trykk' },
    flow: { value: '4.8', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '118', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:25:06+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:25:06.000Z',
      unixTimestamp: '1612344306000',
    },
    pressure: { value: '43.1', key: 'Trykk' },
    flow: { value: '3.3', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '119', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:25:22+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:25:22.000Z',
      unixTimestamp: '1612344322000',
    },
    pressure: { value: '43.1', key: 'Trykk' },
    flow: { value: '3.6', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '120', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:25:38+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:25:38.000Z',
      unixTimestamp: '1612344338000',
    },
    pressure: { value: '43.1', key: 'Trykk' },
    flow: { value: '3.1', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '121', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:25:54+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:25:54.000Z',
      unixTimestamp: '1612344354000',
    },
    pressure: { value: '42.8', key: 'Trykk' },
    flow: { value: '2.8', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '122', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:26:10+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:26:10.000Z',
      unixTimestamp: '1612344370000',
    },
    pressure: { value: '43', key: 'Trykk' },
    flow: { value: '2.9', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '123', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:26:26+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:26:26.000Z',
      unixTimestamp: '1612344386000',
    },
    pressure: { value: '41.7', key: 'Trykk' },
    flow: { value: '2.2', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '124', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:26:42+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:26:42.000Z',
      unixTimestamp: '1612344402000',
    },
    pressure: { value: '43.1', key: 'Trykk' },
    flow: { value: '3.2', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '125', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:26:58+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:26:58.000Z',
      unixTimestamp: '1612344418000',
    },
    pressure: { value: '43.1', key: 'Trykk' },
    flow: { value: '3.2', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '125', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:27:14+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:27:14.000Z',
      unixTimestamp: '1612344434000',
    },
    pressure: { value: '43.3', key: 'Trykk' },
    flow: { value: '3.3', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '126', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:27:30+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:27:30.000Z',
      unixTimestamp: '1612344450000',
    },
    pressure: { value: '43.2', key: 'Trykk' },
    flow: { value: '3.2', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '127', key: 'Volume' },
  },
  {
    logTime: {
      value: '2021-02-03T09:27:46+01:00',
      key: 'LogTime',
      moment: '2021-02-03T09:27:46.000Z',
      unixTimestamp: '1612344466000',
    },
    pressure: { value: '43.5', key: 'Trykk' },
    flow: { value: '3.3', key: 'Flow' },
    pumpNr: { value: '3', key: 'PumpeNr' },
    hole: { value: '1', key: 'Hull' },
    volume: { value: '128', key: 'Volume' },
  },
];

const dummyHole: Hole = {
  number: '1',
  logs: logs,
};

export { dummyHole };
