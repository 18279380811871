const checkFile = (event: Event): boolean => {
  const element = event.currentTarget as HTMLInputElement;
  const fileList: FileList | null = element.files;
  if (fileList === null) return false;
  if (fileList.length !== 1) return false;
  const file = fileList[0];
  if (file.type !== 'text/xml') return false;
  return true;
};
export { checkFile };
