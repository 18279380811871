class LocalStorage {
  private name: string = 'rock-grouting-toolkit';
  public storage: { [key: string]: string } = {};

  constructor(defaultOptions) {
    window.localStorage[this.name]
      ? this.loadLocalStorage()
      : this.createLocalStorage(defaultOptions);
  }
  loadLocalStorage = () => {
    const data = JSON.parse(window.localStorage[this.name]);
    Object.keys(data).forEach((key) => (this.storage[key] = data[key]));
  };
  createLocalStorage = (defaultOptions) => {
    defaultOptions.forEach((option) => {
      const [key, value] = <Array<string>>Object.entries(option)[0];
      this.storage[key] = value;
    });
    this.updateLocalStorage();
  };
  updateLocalStorage = () =>
    (window.localStorage[this.name] = JSON.stringify(this.storage));
  set = (key: string, value: string) => {
    this.storage[key] = value;
    this.updateLocalStorage();
  };
  get = (key: string) => this.storage[key];
}

export { LocalStorage };
