import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings/settings.service';
/**
 * jQuery
 */
declare const $: any;
@Component({
  selector: 'app-settings-general',
  templateUrl: './settings-general.component.html',
  styleUrls: ['./settings-general.component.scss'],
})
export class SettingsGeneralComponent implements OnInit {
  constructor(private settingsService: SettingsService) {}
  public s = this.settingsService.settings;
  public settings = this.s.generalOptions;
  ngOnInit(): void {
    const _this = this;
    _this.updateLocales();

    $(function () {
      $('.js-custom-select').each(function () {
        const select2 = $.HSCore.components.HSSelect2.init($(this));
        select2.on('select2:select', (e) => {
          const key = e.target.id;
          const value = e.target.value;

          // const key = e.params.data.element.parentElement.id;
          // const value = e.params.data.id;
          _this.s.setOption(key, value);
          _this.updateLocales();
        });
      });
    });
  }

  inputRadioChange(event) {
    const key = event.target.name;
    const value = event.target.id;

    this.s.setOption(key, value);
    this.updateLocales();
  }

  updateLocales(): void {
    this.s.options.forEach((option) => {
      option.locales = this.s.getSettingsLocale(option.id);
    });
  }
  getOptionTemplate(settingId, optionKey, optionValue) {
    if (settingId !== 'language') return '';
    const flags = {
      en: 'gb',
      sv: 'se',
    };
    const flag = flags[optionKey];
    return `<span class="d-flex align-items-center"><img class="avatar avatar-xss avatar-circle mr-2" src="assets/svg/flags/1x1/${flag}.svg" alt="${optionValue}" /><span class="font-weight-bold text-primary">${optionValue}</span></span>`;
  }
}
