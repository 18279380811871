<!-- ========== FOOTER ========== -->
<footer class="container text-center space-1">
  <!-- Logo -->
  <a class="d-inline-flex align-items-center mb-2" href="#" aria-label="Front">
    <img
      width="30%"
      style="margin: auto"
      src="../assets/svg/logos/logo.svg"
      alt="Rock Grouting Toolkit"
    />
  </a>
  <!-- End Logo -->

  <p class="small text-muted mb-0">
    Created by
    <a
      href="http://linkedin.com/in/andrewisen/"
      target="_blank"
      rel="noopener noreferrer"
      >André Wisén</a
    >
    &amp;
    <a
      href="https://www.linkedin.com/in/louisesjolund/"
      target="_blank"
      rel="noopener noreferrer"
      >Louise Sjölund</a
    >
  </p>
</footer>
<!-- ========== END FOOTER ========== -->
